import PropTypes from 'prop-types';
import React from 'react';

const RightArrowIcon = ({ className, color = '#2D3942', ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.98985 10.4073L0.820313 9.10781L4.16185 5.39499L0.820313 1.68218L1.98985 0.38269L6.50092 5.39499L1.98985 10.4073Z"
      fill={color}
    />
  </svg>
);

RightArrowIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

RightArrowIcon.defaultProps = {
  className: ''
};

export default RightArrowIcon;
