import React from 'react';
import PropTypes from 'prop-types';

const Listening = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 14.4545V12C21 7.02955 16.9705 3 12 3V3C7.02955 3 3 7.02955 3 12V14.4545"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.90909 14.4546H3V19.3637C3 20.2678 3.73227 21 4.63636 21H7.90909V14.4546Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9999 14.4546H16.0908V21H19.3635C20.2676 21 20.9999 20.2678 20.9999 19.3637V14.4546Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
    />
  </svg>
);

Listening.propTypes = {
  className: PropTypes.string
};

Listening.defaultProps = {
  className: ''
};

export default Listening;
