import React from 'react';
import PropTypes from 'prop-types';

const PlayTriangleIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.91564 6.36604L1.05849 11.2548C0.963065 11.3226 0.852208 11.3574 0.74135 11.3574C0.648779 11.3574 0.556208 11.3336 0.471636 11.2853C0.285922 11.179 0.169922 10.9718 0.169922 10.7463V0.968903C0.169922 0.743411 0.285922 0.536251 0.471636 0.429922C0.656779 0.323592 0.883065 0.334592 1.05792 0.460476L7.91506 5.34919C8.07449 5.46285 8.16992 5.65351 8.16992 5.85762C8.16992 6.06172 8.07449 6.25238 7.91564 6.36604Z"
      fill="currentColor"
    />
  </svg>
);

PlayTriangleIcon.propTypes = {
  className: PropTypes.string
};

PlayTriangleIcon.defaultProps = {
  className: ''
};

export default PlayTriangleIcon;
