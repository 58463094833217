import React from 'react';
import PropTypes from 'prop-types';

const Menu = ({ className, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
    className={className}
    fill="currentColor"
    viewBox="0 0 31 21"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.0481 9.5H1.0481C0.448096 9.5 0.0480957 9.9 0.0480957 10.5C0.0480957 11.1 0.448096 11.5 1.0481 11.5H29.0481C29.6481 11.5 30.0481 11.1 30.0481 10.5C30.0481 9.9 29.6481 9.5 29.0481 9.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.0481 0.5H1.0481C0.448096 0.5 0.0480957 0.9 0.0480957 1.5C0.0480957 2.1 0.448096 2.5 1.0481 2.5H29.0481C29.6481 2.5 30.0481 2.1 30.0481 1.5C30.0481 0.9 29.6481 0.5 29.0481 0.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.0481 18.5H1.0481C0.448096 18.5 0.0480957 18.9 0.0480957 19.5C0.0480957 20.1 0.448096 20.5 1.0481 20.5H29.0481C29.6481 20.5 30.0481 20.1 30.0481 19.5C30.0481 18.9 29.6481 18.5 29.0481 18.5Z"
    />
  </svg>
);

Menu.propTypes = {
  className: PropTypes.string
};

Menu.defaultProps = {
  className: ''
};

export default Menu;
