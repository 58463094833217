import React from 'react';
import PropTypes from 'prop-types';

const LongArrowDown = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 11 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0065 10.419C9.91201 10.3192 9.78062 10.2626 9.64317 10.2626C9.50572 10.2626 9.37433 10.3192 9.27989 10.419L9.10147 10.6077L6.2511 13.6218L6.2511 1.00098C6.2511 0.724834 6.02724 0.500976 5.7511 0.500976L5.2489 0.500976C4.97275 0.500976 4.7489 0.724834 4.7489 1.00098L4.7489 13.6218L1.72011 10.419C1.62567 10.3192 1.49428 10.2626 1.35683 10.2626C1.21938 10.2626 1.08799 10.3192 0.993544 10.419L0.636716 10.7964C0.454428 10.9891 0.454428 11.2907 0.636716 11.4835L4.88561 15.9765L5.13672 16.242C5.23116 16.3419 5.36255 16.3984 5.5 16.3984C5.63745 16.3984 5.76884 16.3419 5.86328 16.242L6.11438 15.9765L5.76594 15.6469L6.11439 15.9765L10.3633 11.4835C10.5456 11.2907 10.5456 10.9891 10.3633 10.7964L10.0065 10.419Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);

LongArrowDown.propTypes = {
  className: PropTypes.string
};

LongArrowDown.defaultProps = {
  className: ''
};

export default LongArrowDown;
