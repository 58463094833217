import * as React from 'react';

function IconGroup(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8852 8.37809C16.4709 7.96381 15.7992 7.96381 15.3849 8.37809L14.943 7.93615L15.3849 8.37809C14.9706 8.79237 14.9706 9.46405 15.3849 9.87834L14.943 10.3203L15.3849 9.87834C15.7992 10.2926 16.4709 10.2926 16.8852 9.87834C17.2995 9.46405 17.2995 8.79238 16.8852 8.37809ZM17.7683 7.4935C16.8658 6.59177 15.4032 6.59201 14.501 7.49421C13.5986 8.39665 13.5986 9.85978 14.501 10.7622C15.4035 11.6647 16.8666 11.6647 17.7691 10.7622C18.6715 9.85978 18.6715 8.39665 17.7691 7.49421C17.7688 7.49397 17.7686 7.49373 17.7683 7.4935Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3937 5.36875C10.6241 4.59909 9.37619 4.59909 8.60654 5.36874C7.83688 6.13841 7.83688 7.38627 8.60654 8.15593C9.3762 8.92559 10.6241 8.92558 11.3937 8.15593C12.1634 7.38626 12.1634 6.1384 11.3937 5.36875ZM12.2773 4.48455C11.0194 3.22705 8.98036 3.22715 7.72266 4.48486L7.72265 4.48486C6.46485 5.74268 6.46484 7.782 7.72266 9.03981L8.15981 8.60266L7.72266 9.03981C8.98047 10.2976 11.0198 10.2976 12.2776 9.03981L11.8357 8.59787L12.2776 9.03981C13.5354 7.78199 13.5354 5.74267 12.2776 4.48486C12.2775 4.48476 12.2774 4.48465 12.2773 4.48455Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61515 8.37809C4.20087 7.96381 3.52919 7.96381 3.11491 8.37809L2.67297 7.93615L3.11491 8.37809C2.70063 8.79237 2.70063 9.46405 3.11491 9.87834L2.67297 10.3203L3.11491 9.87834C3.52919 10.2926 4.20087 10.2926 4.61515 9.87834C5.02943 9.46405 5.02943 8.79238 4.61515 8.37809ZM5.49832 7.4935C4.59583 6.59177 3.13323 6.59201 2.23103 7.49421C1.32859 8.39665 1.32859 9.85978 2.23103 10.7622C3.13346 11.6647 4.5966 11.6647 5.49904 10.7622C6.40147 9.85978 6.40147 8.39665 5.49904 7.49421C5.4988 7.49397 5.49856 7.49373 5.49832 7.4935Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.791 12.8364C15.791 12.4912 16.0708 12.2114 16.416 12.2114H17.0835C18.5795 12.2114 19.7918 13.4237 19.7918 14.9198V15.8331C19.7918 16.1783 19.512 16.4581 19.1668 16.4581C18.8217 16.4581 18.5418 16.1783 18.5418 15.8331V14.9198C18.5418 14.1141 17.8892 13.4614 17.0835 13.4614H16.416C16.0708 13.4614 15.791 13.1816 15.791 12.8364Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91683 13.4614C2.11117 13.4614 1.4585 14.1141 1.4585 14.9198V15.8331C1.4585 16.1783 1.17867 16.4581 0.833496 16.4581C0.488318 16.4581 0.208496 16.1783 0.208496 15.8331V14.9198C0.208496 13.4237 1.42082 12.2114 2.91683 12.2114H3.58433C3.92951 12.2114 4.20933 12.4912 4.20933 12.8364C4.20933 13.1816 3.92951 13.4614 3.58433 13.4614H2.91683Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92529 14.4992C4.92529 12.5432 6.51095 10.9575 8.46696 10.9575H11.5328C13.4888 10.9575 15.0745 12.5432 15.0745 14.4992V15.8334C15.0745 16.1785 14.7946 16.4584 14.4495 16.4584C14.1043 16.4584 13.8245 16.1785 13.8245 15.8334V14.4992C13.8245 13.2335 12.7984 12.2075 11.5328 12.2075H8.46696C7.2013 12.2075 6.17529 13.2335 6.17529 14.4992V15.8334C6.17529 16.1785 5.89547 16.4584 5.55029 16.4584C5.20511 16.4584 4.92529 16.1785 4.92529 15.8334V14.4992Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconGroup);
