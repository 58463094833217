import React from 'react';
import PropTypes from 'prop-types';

const GlyphsMaterial = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      stroke="#607187"
      strokeWidth="1.8"
      d="M22.968 15c2.743 0 4.968-2.239 4.968-5s-2.225-5-4.968-5C20.224 5 18 7.239 18 10s2.224 5 4.968 5z"
      clipRule="evenodd"
    />
    <rect
      width="9.742"
      height="9.818"
      x="9"
      y="18"
      stroke="#607187"
      strokeWidth="1.8"
      rx=".9"
    />
    <path
      stroke="#607187"
      strokeLinejoin="round"
      strokeWidth="1.8"
      d="M7.871 3L2 12.818h11.742L7.87 3z"
      clipRule="evenodd"
    />
  </svg>
);

GlyphsMaterial.propTypes = {
  className: PropTypes.string
};

GlyphsMaterial.defaultProps = {
  className: ''
};

export default GlyphsMaterial;
