import React from 'react';
import PropTypes from 'prop-types';

const Headphone = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.736 11.841v-2.21a8.105 8.105 0 0 0-16.21 0v2.21"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      clipRule="evenodd"
      d="M5.946 11.844h-4.42v4.42c0 .815.659 1.474 1.473 1.474h2.947v-5.894zM17.736 11.844h-4.421v5.894h2.947c.814 0 1.474-.659 1.474-1.473v-4.421z"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

Headphone.propTypes = {
  className: PropTypes.string
};

Headphone.defaultProps = {
  className: ''
};

export default Headphone;
