import React from 'react';
import PropTypes from 'prop-types';

const CourseIntake = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8893 8.96399L6.22266 4.96399V8.29732H0.222656V9.63066H6.22266V12.964L10.8893 8.96399Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.556 16.964H0.889323C0.521323 16.964 0.222656 16.6653 0.222656 16.2973V12.964H1.55599V15.6307H14.8893V2.29732H1.55599V4.96399H0.222656V1.63066C0.222656 1.26266 0.521323 0.963989 0.889323 0.963989H15.556C15.7327 0.963989 15.9027 1.03399 16.0273 1.15932C16.152 1.28466 16.2227 1.45399 16.2227 1.63066V16.2973C16.2227 16.6653 15.924 16.964 15.556 16.964Z"
      fill="currentColor"
    />
  </svg>
);

CourseIntake.propTypes = {
  className: PropTypes.string
};

CourseIntake.defaultProps = {
  className: ''
};

export default CourseIntake;
