import React from 'react';
import PropTypes from 'prop-types';

const PhoneCall = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 37"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.6279 24.8544L27.4135 23.1024C31.7257 14.8095 25.8984 4.76453 16.6913 4.41413L16.3416 1.61088C27.9962 1.72769 35.4551 14.5759 29.6279 24.8544ZM8.99927 0.910074L12.146 6.39976C12.6122 7.21737 12.3791 8.26859 11.5633 8.7358L6.90144 11.4222C10.1647 16.9119 13.3115 22.5184 16.5747 28.0081L21.12 25.3217C21.9358 24.8544 22.9848 25.2049 23.4509 26.0225L26.5977 31.629C27.0639 32.3298 26.8308 33.381 26.0149 33.8482L21.4697 36.5346C13.1949 41.3235 -6.26822 7.68458 2.00653 2.8957L6.66835 0.209263C7.48417 -0.257945 8.53308 0.0924607 8.99927 0.910074ZM25.1991 21.3504L22.8682 19.5984C24.3833 15.3935 21.8193 10.8382 17.3906 10.1374L17.0409 7.21737C23.8006 7.80138 28.1128 15.1599 25.1991 21.3504Z"
      fill="currentColor"
    />
  </svg>
);

PhoneCall.propTypes = {
  className: PropTypes.string
};

PhoneCall.defaultProps = {
  className: ''
};

export default PhoneCall;
