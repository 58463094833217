import React from 'react';
import PropTypes from 'prop-types';

const TravelIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M48.3333 43.334C32.6853 43.334 20 56.0192 20 71.6673H76.6667C76.6667 56.0192 63.9814 43.334 48.3333 43.334Z"
        fill="#D22222"
      />
      <path
        d="M26.5093 25.521L25.5987 22.1133C23.6283 22.6399 21.6685 23.293 19.7744 24.0538L21.0888 27.3269C22.8531 26.6186 24.676 26.0114 26.5093 25.521Z"
        fill="black"
      />
      <path
        d="M15.9235 29.7717L14.2264 26.6797C12.439 27.6613 10.6912 28.7624 9.03125 29.9528L11.0875 32.8184C12.6334 31.7104 14.2601 30.6853 15.9235 29.7717Z"
        fill="black"
      />
      <path
        d="M0 38.2473L2.68107 40.5388C3.91547 39.0945 5.25173 37.7108 6.6512 36.4263L4.2664 33.8281C2.76213 35.2084 1.32667 36.6948 0 38.2473Z"
        fill="black"
      />
      <path
        d="M76.1244 36.8262L73.5273 39.212C74.8135 40.6123 76.0268 42.1048 77.1348 43.6472L80.0007 41.591C78.8098 39.9328 77.5065 38.3296 76.1244 36.8262Z"
        fill="black"
      />
      <path
        d="M64.8486 31.8008C66.4305 32.8501 67.967 34.0088 69.4126 35.2432L71.7028 32.5611C70.1492 31.2339 68.4985 29.9888 66.7966 28.8613L64.8486 31.8008Z"
        fill="black"
      />
      <path
        d="M61.4762 25.7847C59.6538 24.874 57.754 24.0642 55.8266 23.377L54.6426 26.6994C56.4362 27.3383 58.2042 28.0919 59.8996 28.9396L61.4762 25.7847Z"
        fill="black"
      />
      <path
        d="M29.5774 28.1614L31.9745 28.1625L33.9726 25.1323L37.8201 25.1587L35.3977 33.5107V33.7083C35.3977 34.763 36.2558 35.6217 37.3105 35.6217H40.7857L46.3006 25.3939L50.563 25.535C52.7691 25.535 54.5644 23.8038 54.5644 21.6771C54.5654 20.6321 54.1393 19.6531 53.3652 18.9214C52.614 18.2113 51.6198 17.8193 50.5212 17.8193C50.5193 17.8193 50.5182 17.8193 50.5177 17.8193L46.2641 17.9614L40.7867 7.8038L37.3057 7.80273C36.2534 7.80567 35.3977 8.6646 35.3977 9.7166V9.9134L37.8006 18.1987L33.9369 18.2323L31.9734 15.2619L29.5779 15.2625C28.5939 15.2625 27.7939 16.0625 27.7939 17.0459V26.3782C27.7939 27.3619 28.5931 28.1614 29.5774 28.1614ZM30.5678 18.1702H30.5689L32.4534 21.0211L41.4873 20.943L38.4806 10.5785H39.1297L44.6382 20.7931L50.5646 20.595C50.9062 20.595 51.2236 20.7174 51.4588 20.939C51.6721 21.1406 51.7892 21.4022 51.7892 21.6766C51.7892 22.2745 51.239 22.7601 50.6094 22.7606L44.6747 22.5643L39.1291 32.8467H38.4801L41.5067 22.4099L32.4854 22.347L30.5678 25.2526V18.1702Z"
        fill="black"
      />
      <path
        d="M45.6811 41.7657C43.3726 41.2396 41.0126 40.9727 38.6654 40.9727C23.9022 40.9727 11.2966 51.0409 8.01234 65.4564C7.50221 67.6972 7.24701 69.954 7.22754 72.1983H10.755C10.7702 70.7951 10.8883 69.386 11.1177 67.9801C13.1011 68.4887 15.1443 69.3044 15.8009 69.8401C15.7702 70.0676 15.735 70.298 15.6993 70.5303C15.6198 71.0447 15.5342 71.6137 15.4987 72.1985H19.0374C19.0745 71.7988 19.1374 71.3775 19.1849 71.0676C19.2502 70.6425 19.3147 70.2193 19.3553 69.8103C19.5745 67.5953 17.0627 65.8241 11.8993 64.5409C14.7395 54.8529 22.4185 47.6463 31.9419 45.3124C31.4171 46.3271 30.9945 47.5825 30.8179 49.1708C30.7321 49.9492 30.6203 50.6521 30.5222 51.2711C30.1273 53.7671 29.7187 56.3481 33.0939 57.8505C34.9867 58.6959 36.311 58.8359 37.3745 58.9468C37.8918 59.0009 38.3478 59.0492 38.8323 59.1732C39.0033 59.7463 38.9169 61.1465 38.8822 61.7063C38.8382 62.4169 38.7934 63.1423 39.0105 63.7348C39.2262 64.9457 40.1214 68.7812 43.0918 72.1972H48.3998C43.2974 68.866 42.4846 63.1711 42.451 62.9207L42.3787 62.3399C42.3857 62.2023 42.3947 62.0519 42.4027 61.9231C42.5161 60.0873 42.7278 56.6684 40.1107 55.8689C39.2067 55.5932 38.4297 55.5121 37.7441 55.4399C36.7611 55.3364 35.9129 55.2471 34.5305 54.6305C33.6257 54.2273 33.6257 54.2273 34.0062 51.8231C34.1102 51.1652 34.2283 50.4188 34.323 49.5583C34.5923 47.13 35.6155 45.5911 36.5398 44.5828C37.2422 44.5305 37.9505 44.4988 38.6654 44.4988C40.7491 44.4988 42.8459 44.7356 44.8982 45.2031C52.1761 46.8612 58.3731 51.2535 62.3462 57.5705C65.039 61.8524 66.4881 66.6713 66.6286 71.5985C66.3353 71.7908 65.9702 71.9935 65.5777 72.1975H70.1574C70.1246 66.4039 68.4931 60.7201 65.3318 55.6935C60.8566 48.5799 53.8779 43.6332 45.6811 41.7657Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

TravelIcon.propTypes = {
  className: PropTypes.string
};

TravelIcon.defaultProps = {
  className: ''
};

export default TravelIcon;
