import React from 'react';
import PropTypes from 'prop-types';

const WelcomListIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.45667"
      y="0.648376"
      width="5.90552"
      height="5.90552"
      transform="rotate(45 4.45667 0.648376)"
      fill="#D22222"
    />
  </svg>
);

WelcomListIcon.propTypes = {
  className: PropTypes.string
};

WelcomListIcon.defaultProps = {
  className: ''
};

export default WelcomListIcon;
