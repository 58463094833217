import React from 'react';
import PropTypes from 'prop-types';

const Facebook = ({ className, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
    className={className}
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41834 15L6.4 9.3125H4V6.875H6.4V5.25C6.4 3.0569 7.73721 2 9.6635 2C10.5862 2 11.3792 2.06977 11.6104 2.10095V4.39287L10.2744 4.39349C9.22674 4.39349 9.0239 4.89908 9.0239 5.64101V6.875H12L11.2 9.3125H9.02389V15H6.41834Z"
    />
  </svg>
);

Facebook.propTypes = {
  className: PropTypes.string
};

Facebook.defaultProps = {
  className: ''
};

export default Facebook;
