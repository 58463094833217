import React from 'react';
import PropTypes from 'prop-types';

const Location = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 28 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0C6.1312 0.164994 -0.122425 6.64103 0.00181968 14.437V14.5195C0.00181968 17.2007 0.747284 19.8406 2.1968 22.1505L12.2606 36.0925C12.6747 36.67 13.3374 37 14 37C14.4141 37 14.8697 36.8763 15.2424 36.6288C15.4495 36.505 15.6152 36.2988 15.7394 36.1338L24.6022 23.8417L25.8032 22.1918C27.2113 19.8818 27.9568 17.2419 27.9982 14.5608V14.4783C28.1224 6.64103 21.8688 0.164994 14 0ZM14 21.7793C9.65145 21.7793 6.1312 18.2731 6.1312 13.942C6.1312 9.61093 9.65145 6.10479 14 6.10479C18.3485 6.10479 21.8688 9.61093 21.8688 13.942C21.8688 18.2731 18.3485 21.7793 14 21.7793Z"
      fill="currentColor"
    />
  </svg>
);

Location.propTypes = {
  className: PropTypes.string
};

Location.defaultProps = {
  className: ''
};

export default Location;
