import React from 'react';
import PropTypes from 'prop-types';

const InfiniteIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 24 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 0.820312C16.309 0.820312 13.815 2.71531 12 4.42531C10.185 2.71531 7.691 0.820312 5 0.820312C2.243 0.820312 0 3.06331 0 5.82031C0 8.57731 2.243 10.8203 5 10.8203C7.691 10.8203 10.185 8.92531 12 7.21531C13.815 8.92531 16.309 10.8203 19 10.8203C21.757 10.8203 24 8.57731 24 5.82031C24 3.06331 21.757 0.820312 19 0.820312ZM5 8.82031C3.346 8.82031 2 7.47431 2 5.82031C2 4.16631 3.346 2.82031 5 2.82031C6.567 2.82031 8.392 3.80331 10.561 5.82031C8.392 7.83731 6.567 8.82031 5 8.82031ZM13.439 5.82031C15.608 7.83731 17.433 8.82031 19 8.82031C20.654 8.82031 22 7.47431 22 5.82031C22 4.16631 20.654 2.82031 19 2.82031C17.433 2.82031 15.608 3.80331 13.439 5.82031Z"
      fill="currentColor"
    />
  </svg>
);
InfiniteIcon.propTypes = {
  className: PropTypes.string
};

InfiniteIcon.defaultProps = {
  className: ''
};

export default InfiniteIcon;
