import React from 'react';
import PropTypes from 'prop-types';

const TPScrollIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7188 3.37525C17.3558 3.37525 17.0625 3.07285 17.0625 2.70025C17.0625 1.95573 16.4738 1.35025 15.75 1.35025H2.625C2.26275 1.35025 1.96875 1.04785 1.96875 0.675255C1.96875 0.302655 2.26275 0.000254594 2.625 0.000254594H15.75C17.1977 0.000254594 18.375 1.2112 18.375 2.70025C18.375 3.07285 18.0817 3.37525 17.7188 3.37525ZM17.7188 16.8753C17.3558 16.8753 17.0625 16.5735 17.0625 16.2003V2.70025C17.0625 2.32765 17.3558 2.02525 17.7188 2.02525C18.0817 2.02525 18.375 2.32765 18.375 2.70025V16.2003C18.375 16.5735 18.0817 16.8753 17.7188 16.8753ZM17.7188 3.37525C17.3558 3.37525 17.0625 3.07285 17.0625 2.70025C17.0625 1.95573 16.4738 1.35025 15.75 1.35025H2.625C2.26275 1.35025 1.96875 1.04785 1.96875 0.675255C1.96875 0.302655 2.26275 0.000254594 2.625 0.000254594H15.75C17.1977 0.000254594 18.375 1.2112 18.375 2.70025C18.375 3.07285 18.0817 3.37525 17.7188 3.37525ZM17.7188 20.2503C16.0388 20.2503 7.15509 18.9914 6.49819 18.8976C5.10694 18.8051 3.9375 17.5246 3.9375 16.0855V4.72525H0.65625C0.294 4.72525 0 4.42285 0 4.05025V2.815C0 1.3138 1.09134 0.0792296 2.48522 0.00430462C3.21825 -0.0375454 3.90469 0.227055 4.43166 0.741405C4.95141 1.249 5.25 1.96315 5.25 2.70025V16.0855C5.25 16.826 5.87606 17.5084 6.59072 17.5509L6.60253 17.5516C6.93197 17.5658 7.23778 17.4571 7.47009 17.2303C7.72734 16.9792 7.875 16.6039 7.875 16.2003C7.875 15.827 8.169 15.5253 8.53125 15.5253H20.3438C20.7067 15.5253 21 15.827 21 16.2003V16.8759C21 18.8307 19.6199 20.2503 17.7188 20.2503ZM8.68481 17.8412C12.0934 18.3096 16.6582 18.9003 17.7188 18.9003C19.0785 18.9003 19.6875 17.8837 19.6875 16.8759L9.11006 16.8753C9.02869 17.2263 8.88431 17.555 8.68481 17.8412ZM1.3125 3.37525H3.9375V2.70025C3.9375 2.3263 3.79181 1.978 3.528 1.72083C3.26484 1.46365 2.92031 1.33203 2.55347 1.35228C1.869 1.3894 1.3125 2.0455 1.3125 2.815V3.37525Z"
      fill="currentColor"
    />
  </svg>
);

TPScrollIcon.propTypes = {
  className: PropTypes.string
};

TPScrollIcon.defaultProps = {
  className: ''
};

export default TPScrollIcon;
