import React from 'react';

const ListBulletIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M3 5.4A1.2 1.2 0 103 3a1.2 1.2 0 000 2.4zM3 10.2a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4zM3 15a1.2 1.2 0 100-2.4A1.2 1.2 0 003 15zM15.6 4.8h-9a.6.6 0 010-1.2h9a.6.6 0 110 1.2zM15.6 9.6h-9a.6.6 0 010-1.2h9a.6.6 0 110 1.2zM15.6 14.4h-9a.6.6 0 110-1.2h9a.6.6 0 110 1.2z"
      fill="#AB2E32"
    />
  </svg>
);

export default ListBulletIcon;
