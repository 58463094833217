import React from 'react';
import PropTypes from 'prop-types';

const FilterIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 11.2635C3.99952 10.7975 3.85727 10.3443 3.59483 9.97277C3.33239 9.60122 2.96406 9.33153 2.54571 9.2046V0.739745C2.54571 0.66312 2.53163 0.587244 2.50427 0.516451C2.47691 0.445658 2.43681 0.381334 2.38626 0.327152C2.33571 0.272969 2.27569 0.229989 2.20965 0.200666C2.1436 0.171343 2.07281 0.15625 2.00132 0.15625C1.92983 0.15625 1.85904 0.171343 1.79299 0.200666C1.72694 0.229989 1.66693 0.272969 1.61638 0.327152C1.56583 0.381334 1.52573 0.445658 1.49837 0.516451C1.47101 0.587244 1.45693 0.66312 1.45693 0.739745V9.2046C1.03776 9.3304 0.668483 9.59971 0.405434 9.97144C0.142384 10.3432 0 10.7969 0 11.2635C0 11.7301 0.142384 12.1838 0.405434 12.5556C0.668483 12.9273 1.03776 13.1966 1.45693 13.3224V14.5728C1.45693 14.7275 1.51429 14.8759 1.61638 14.9853C1.71847 15.0948 1.85694 15.1562 2.00132 15.1562C2.1457 15.1562 2.28417 15.0948 2.38626 14.9853C2.48835 14.8759 2.54571 14.7275 2.54571 14.5728V13.3224C2.96406 13.1955 3.33239 12.9258 3.59483 12.5542C3.85727 12.1827 3.99952 11.7295 4 11.2635ZM2.00132 12.2429C1.82136 12.2429 1.64543 12.1857 1.4958 12.0786C1.34617 11.9714 1.22954 11.8191 1.16067 11.6409C1.09181 11.4627 1.07379 11.2666 1.1089 11.0774C1.144 10.8882 1.23066 10.7144 1.35792 10.578C1.48517 10.4417 1.6473 10.3488 1.8238 10.3111C2.00031 10.2735 2.18326 10.2928 2.34952 10.3666C2.51579 10.4405 2.6579 10.5655 2.75788 10.7258C2.85786 10.8862 2.91122 11.0748 2.91122 11.2677C2.91122 11.5263 2.81536 11.7744 2.64472 11.9573C2.47408 12.1402 2.24264 12.2429 2.00132 12.2429Z"
      fill="black"
    />
    <path
      d="M15 11.2635C14.9995 10.7975 14.8573 10.3443 14.5948 9.97277C14.3324 9.60122 13.9641 9.33153 13.5457 9.2046V0.739745C13.5457 0.584993 13.4884 0.436578 13.3863 0.327152C13.2842 0.217725 13.1457 0.15625 13.0013 0.15625C12.8569 0.15625 12.7185 0.217725 12.6164 0.327152C12.5143 0.436578 12.4569 0.584993 12.4569 0.739745V9.2046C12.0378 9.3304 11.6685 9.59971 11.4054 9.97144C11.1424 10.3432 11 10.7969 11 11.2635C11 11.7301 11.1424 12.1838 11.4054 12.5556C11.6685 12.9273 12.0378 13.1966 12.4569 13.3224V14.5728C12.4569 14.7275 12.5143 14.8759 12.6164 14.9853C12.7185 15.0948 12.8569 15.1562 13.0013 15.1562C13.1457 15.1562 13.2842 15.0948 13.3863 14.9853C13.4884 14.8759 13.5457 14.7275 13.5457 14.5728V13.3224C13.9641 13.1955 14.3324 12.9258 14.5948 12.5542C14.8573 12.1827 14.9995 11.7295 15 11.2635ZM13.0013 12.2429C12.8214 12.2429 12.6454 12.1857 12.4958 12.0786C12.3462 11.9714 12.2295 11.8191 12.1607 11.6409C12.0918 11.4627 12.0738 11.2666 12.1089 11.0774C12.144 10.8882 12.2307 10.7144 12.3579 10.578C12.4852 10.4417 12.6473 10.3488 12.8238 10.3111C13.0003 10.2735 13.1833 10.2928 13.3495 10.3666C13.5158 10.4405 13.6579 10.5655 13.7579 10.7258C13.8579 10.8862 13.9112 11.0748 13.9112 11.2677C13.9112 11.3957 13.8877 11.5226 13.842 11.6409C13.7962 11.7592 13.7292 11.8667 13.6447 11.9573C13.5602 12.0479 13.4599 12.1197 13.3495 12.1687C13.2391 12.2177 13.1208 12.2429 13.0013 12.2429Z"
      fill="black"
    />
    <path
      d="M5 4.049C5.0006 4.51502 5.17841 4.96817 5.50646 5.33973C5.83452 5.71128 6.29493 5.98097 6.81787 6.1079V14.5728C6.81787 14.7275 6.88956 14.8759 7.01718 14.9853C7.14479 15.0948 7.31788 15.1562 7.49835 15.1562C7.67883 15.1562 7.85191 15.0948 7.97953 14.9853C8.10714 14.8759 8.17884 14.7275 8.17884 14.5728V6.1079C8.7028 5.9821 9.16439 5.71279 9.49321 5.34106C9.82202 4.96932 10 4.51556 10 4.049C10 3.58243 9.82202 3.12868 9.49321 2.75694C9.16439 2.3852 8.7028 2.11589 8.17884 1.99009V0.739745C8.17884 0.584993 8.10714 0.436578 7.97953 0.327152C7.85191 0.217725 7.67883 0.15625 7.49835 0.15625C7.31788 0.15625 7.14479 0.217725 7.01718 0.327152C6.88956 0.436578 6.81787 0.584993 6.81787 0.739745V1.99009C6.29493 2.11703 5.83452 2.38672 5.50646 2.75827C5.17841 3.12982 5.0006 3.58297 5 4.049ZM7.49835 3.06956C7.7233 3.06956 7.94321 3.12676 8.13025 3.23392C8.31729 3.34109 8.46307 3.4934 8.54916 3.67161C8.63524 3.84982 8.65777 4.04591 8.61388 4.2351C8.56999 4.42428 8.46167 4.59806 8.3026 4.73445C8.14354 4.87085 7.94087 4.96373 7.72024 5.00136C7.49961 5.03899 7.27092 5.01968 7.06309 4.94586C6.85527 4.87205 6.67763 4.74704 6.55265 4.58666C6.42768 4.42628 6.36097 4.23772 6.36097 4.04483C6.36097 3.78617 6.4808 3.53811 6.6941 3.35521C6.9074 3.17231 7.1967 3.06956 7.49835 3.06956Z"
      fill="black"
    />
  </svg>
);

FilterIcon.propTypes = {
  className: PropTypes.string
};

FilterIcon.defaultProps = {
  className: ''
};

export default FilterIcon;
