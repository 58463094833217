import React from 'react';
import PropTypes from 'prop-types';

const DesktopIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13.5" cy="13.5" r="13.5" fill="#D22222" />
    <path
      d="M14.7 18.9V19.6H16.8C17.1866 19.6 17.5 19.9134 17.5 20.3C17.5 20.6866 17.1866 21 16.8 21H11.2C10.8134 21 10.5 20.6866 10.5 20.3C10.5 19.9134 10.8134 19.6 11.2 19.6H13.3V18.9H9.1C7.9402 18.9 7 17.9598 7 16.8V9.1C7 7.9402 7.9402 7 9.1 7H18.9C20.0598 7 21 7.9402 21 9.1V16.8C21 17.9598 20.0598 18.9 18.9 18.9H14.7ZM9.1 8.4C8.7134 8.4 8.4 8.7134 8.4 9.1V16.8C8.4 17.1866 8.7134 17.5 9.1 17.5H18.9C19.2866 17.5 19.6 17.1866 19.6 16.8V9.1C19.6 8.7134 19.2866 8.4 18.9 8.4H9.1ZM12.6 16.8C12.2134 16.8 11.9 16.4866 11.9 16.1C11.9 15.7134 12.2134 15.4 12.6 15.4H15.4C15.7866 15.4 16.1 15.7134 16.1 16.1C16.1 16.4866 15.7866 16.8 15.4 16.8H12.6Z"
      fill="white"
    />
  </svg>
);

DesktopIcon.propTypes = {
  className: PropTypes.string
};

DesktopIcon.defaultProps = {
  className: ''
};

export default DesktopIcon;
