import React from 'react';
import PropTypes from 'prop-types';

const LibraryIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1699 2.76416C20.1699 1.48896 19.129 0.458984 17.8403 0.458984H2.49954C1.21081 0.458984 0.169922 1.48896 0.169922 2.76416V5.21648C0.169922 5.80503 0.39297 6.34454 0.764717 6.73691C0.39297 7.15381 0.169922 7.66879 0.169922 8.25735V10.7097C0.169922 11.2737 0.39297 11.8132 0.739934 12.2056C0.39297 12.5979 0.169922 13.1375 0.169922 13.7015V16.1538C0.169922 17.429 1.21081 18.459 2.49954 18.459H17.8403C19.129 18.459 20.1699 17.429 20.1699 16.1538V13.7015C20.1699 13.1375 19.9469 12.5979 19.5999 12.2056C19.9469 11.8132 20.1699 11.2737 20.1699 10.7097V8.25735C20.1699 7.66879 19.9469 7.12928 19.5751 6.73691C19.9469 6.32002 20.1699 5.80503 20.1699 5.21648V2.76416ZM1.78083 2.76416C1.78083 2.37179 2.10301 2.05299 2.49954 2.05299H17.8403C18.2368 2.05299 18.559 2.37179 18.559 2.76416V5.21648C18.559 5.60885 18.2368 5.92765 17.8403 5.92765H2.49954C2.10301 5.92765 1.78083 5.60885 1.78083 5.21648V2.76416ZM18.5838 10.7342C18.5838 11.102 18.3112 11.3963 17.9642 11.4454C17.9394 11.4454 17.8899 11.4454 17.8651 11.4454H2.49954C2.47475 11.4454 2.42519 11.4454 2.40041 11.4454C2.05344 11.3963 1.78083 11.102 1.78083 10.7342V8.28187C1.78083 7.8895 2.10301 7.5707 2.49954 7.5707H17.8403C18.2368 7.5707 18.559 7.8895 18.559 8.28187V10.7342H18.5838ZM18.5838 16.2029C18.5838 16.5952 18.2616 16.914 17.8651 16.914H2.49954C2.10301 16.914 1.78083 16.5952 1.78083 16.2029V13.7505C1.78083 13.3827 2.05344 13.0884 2.40041 13.0394C2.42519 13.0394 2.47475 13.0394 2.49954 13.0394H17.8403C17.8651 13.0394 17.9147 13.0394 17.9394 13.0394C18.2864 13.0884 18.559 13.3827 18.559 13.7505V16.2029H18.5838Z"
      fill="currentColor"
    />
    <path
      d="M4.16064 4.99576C4.72182 4.99576 5.17675 4.54561 5.17675 3.99031C5.17675 3.43502 4.72182 2.98486 4.16064 2.98486C3.59946 2.98486 3.14453 3.43502 3.14453 3.99031C3.14453 4.54561 3.59946 4.99576 4.16064 4.99576Z"
      fill="currentColor"
    />
    <path
      d="M4.16064 10.5136C4.72182 10.5136 5.17675 10.0634 5.17675 9.50814C5.17675 8.95284 4.72182 8.50269 4.16064 8.50269C3.59946 8.50269 3.14453 8.95284 3.14453 9.50814C3.14453 10.0634 3.59946 10.5136 4.16064 10.5136Z"
      fill="currentColor"
    />
    <path
      d="M4.16064 15.9821C4.72182 15.9821 5.17675 15.5319 5.17675 14.9766C5.17675 14.4213 4.72182 13.9712 4.16064 13.9712C3.59946 13.9712 3.14453 14.4213 3.14453 14.9766C3.14453 15.5319 3.59946 15.9821 4.16064 15.9821Z"
      fill="currentColor"
    />
  </svg>
);

LibraryIcon.propTypes = {
  className: PropTypes.string
};

LibraryIcon.defaultProps = {
  className: ''
};

export default LibraryIcon;
