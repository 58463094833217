import React from 'react';
import PropTypes from 'prop-types';

const WelcomeDownloadIconBlack = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.36804 17.5H13.368" stroke="black" strokeWidth="2" />
    <path
      d="M7.36804 0V14M7.36804 14L1.36804 9.5M7.36804 14L13.368 9.5"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

WelcomeDownloadIconBlack.propTypes = {
  className: PropTypes.string
};

WelcomeDownloadIconBlack.defaultProps = {
  className: ''
};

export default WelcomeDownloadIconBlack;
