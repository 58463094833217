import React from 'react';
import PropTypes from 'prop-types';

const PauseIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.169922" width="3" height="11" fill="currentColor" />
    <rect x="5.16992" width="3" height="11" fill="currentColor" />
  </svg>
);

PauseIcon.propTypes = {
  className: PropTypes.string
};

PauseIcon.defaultProps = {
  className: ''
};

export default PauseIcon;
