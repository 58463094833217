import React from 'react';
import PropTypes from 'prop-types';

const XCircle = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0C2.24211 0 0 2.24211 0 5C0 7.75789 2.24211 10 5 10C7.75789 10 10 7.75789 10 5C10 2.24211 7.75789 0 5 0ZM6.14737 5.29474L6.84211 5.98947C7.07368 6.22105 7.07368 6.6 6.84211 6.83158C6.72632 6.94737 6.56842 7.01053 6.42105 7.01053C6.27368 7.01053 6.11579 6.94737 6 6.83158L5.30526 6.13684C5.13684 5.96842 4.87368 5.96842 4.70526 6.13684L4.01053 6.83158C3.89474 6.94737 3.73684 7.01053 3.58947 7.01053C3.44211 7.01053 3.28421 6.94737 3.16842 6.83158C2.93684 6.6 2.93684 6.22105 3.16842 5.98947L3.86316 5.29474C4.03158 5.12632 4.03158 4.86316 3.86316 4.69474L3.16842 4C2.93684 3.76842 2.93684 3.38947 3.16842 3.15789C3.4 2.92632 3.77895 2.92632 4.01053 3.15789L4.70526 3.85263C4.87368 4.02105 5.13684 4.02105 5.30526 3.85263L6 3.15789C6.23158 2.92632 6.61053 2.92632 6.84211 3.15789C7.07368 3.38947 7.07368 3.76842 6.84211 4L6.14737 4.69474C5.97895 4.86316 5.97895 5.13684 6.14737 5.29474Z"
      fill="#D22222"
    />
  </svg>
);

XCircle.propTypes = {
  className: PropTypes.string
};

XCircle.defaultProps = {
  className: ''
};

export default XCircle;
