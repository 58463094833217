import React from 'react';
import PropTypes from 'prop-types';

const MobileIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13.5" cy="13.5" r="13.5" fill="#D22222" />
    <path
      d="M17.3098 6H10.6917C10.2192 6.00019 9.76615 6.18786 9.43194 6.52179C9.09773 6.85573 8.9097 7.30864 8.90912 7.78109V20.2175C8.90931 20.6899 9.09698 21.143 9.43091 21.4772C9.76485 21.8114 10.2178 21.9994 10.6902 22H17.3084C17.7808 21.9998 18.2339 21.8121 18.5681 21.4782C18.9023 21.1443 19.0904 20.6914 19.0909 20.2189V7.78109C19.0904 7.30889 18.9025 6.8562 18.5686 6.52231C18.2347 6.18841 17.782 6.00058 17.3098 6ZM10.6917 7.45455H17.3098C17.3965 7.45455 17.4795 7.48895 17.5407 7.55019C17.602 7.61143 17.6364 7.69449 17.6364 7.78109V16.1818H10.3637V7.78109C10.3637 7.69449 10.3981 7.61143 10.4593 7.55019C10.5205 7.48895 10.6036 7.45455 10.6902 7.45455H10.6917ZM17.3098 20.5455H10.6917C10.6487 20.5456 10.606 20.5373 10.5663 20.521C10.5265 20.5047 10.4903 20.4807 10.4598 20.4503C10.4293 20.42 10.4052 20.3839 10.3887 20.3442C10.3722 20.3045 10.3637 20.2619 10.3637 20.2189V17.6364H17.6364V20.2189C17.6364 20.3055 17.602 20.3886 17.5407 20.4498C17.4795 20.5111 17.3965 20.5455 17.3098 20.5455Z"
      fill="white"
    />
    <path
      d="M14.0146 18.3636H14.0073C13.8138 18.3646 13.6285 18.4423 13.4922 18.5796C13.3558 18.717 13.2796 18.9028 13.28 19.0964C13.2805 19.2899 13.3577 19.4753 13.4948 19.612C13.6318 19.7487 13.8174 19.8255 14.0109 19.8255C14.2045 19.8255 14.3901 19.7487 14.5271 19.612C14.6641 19.4753 14.7414 19.2899 14.7418 19.0964C14.7423 18.9028 14.666 18.717 14.5297 18.5796C14.3934 18.4423 14.2081 18.3646 14.0146 18.3636Z"
      fill="white"
    />
  </svg>
);

MobileIcon.propTypes = {
  className: PropTypes.string
};

MobileIcon.defaultProps = {
  className: ''
};

export default MobileIcon;
