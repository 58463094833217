import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1845 10.926L14.723 10.2625C14.3174 10.1531 14.0108 9.83879 13.9287 9.44836L13.778 8.73186C14.9785 8.21417 15.749 7.08366 15.7501 5.83823V4.37974C15.7785 2.6251 14.3354 1.16146 12.4786 1.06152C11.1144 1.02268 9.85968 1.76292 9.29936 2.93717C9.83604 3.67414 10.1239 4.54706 10.1251 5.44123V6.89972C10.1231 7.28586 10.0644 7.66988 9.95073 8.04083C10.2424 8.32949 10.5889 8.5639 10.9722 8.73186L10.8215 9.4473C10.7394 9.83773 10.4328 10.152 10.0272 10.2615L9.07886 10.5173L10.7439 10.9663C11.7079 11.2291 12.3725 12.0607 12.3751 13.0075V15.3917C12.3732 15.5729 12.3382 15.7524 12.2716 15.9224H17.4376C17.7483 15.9224 18.0001 15.6848 18.0001 15.3917V11.9461C17.9999 11.4724 17.6671 11.0562 17.1845 10.926Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4344 11.9875L7.97288 11.3241C7.56698 11.2145 7.26028 10.8997 7.17863 10.5089L7.02788 9.79236C8.22802 9.27484 8.99844 8.14484 9 6.8998V5.44131C9.02835 3.68666 7.58532 2.22302 5.7285 2.12308C4.8156 2.09665 3.93032 2.42046 3.27482 3.02055C2.61932 3.62064 2.24957 4.44579 2.25 5.30756V6.8998C2.25112 8.14522 3.02161 9.27574 4.22213 9.79342L4.07138 10.5089C3.98934 10.8993 3.68271 11.2136 3.27713 11.323L0.815625 11.9865C0.332594 12.1168 -0.000279024 12.5336 1.75501e-07 13.0076V15.3917C1.75501e-07 15.6849 0.25184 15.9225 0.5625 15.9225H10.6875C10.9982 15.9225 11.25 15.6849 11.25 15.3917V13.0076C11.2498 12.534 10.917 12.1178 10.4344 11.9875Z"
      fill="currentColor"
    />
  </svg>
);
UserIcon.propTypes = {
  className: PropTypes.string
};

UserIcon.defaultProps = {
  className: ''
};

export default UserIcon;
