import React from 'react';
import PropTypes from 'prop-types';

const DoubleArrowDown = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.58823 8.23529L14 0L-9.53674e-07 -6.11959e-07L6.58823 8.23529Z"
      fill="black"
    />
    <path
      d="M6.58823 18.9412L14 10.7059L-9.53674e-07 10.7059L6.58823 18.9412Z"
      fill="black"
    />
  </svg>
);

DoubleArrowDown.propTypes = {
  className: PropTypes.string
};

DoubleArrowDown.defaultProps = {
  className: ''
};

export default DoubleArrowDown;
