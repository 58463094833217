import React from 'react';
import PropTypes from 'prop-types';

const BookAndPaper = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.235 4.97009L21.2567 4.95295L21.2764 4.93352C21.3651 4.84588 21.4648 4.81111 21.555 4.81111C21.6452 4.81111 21.7449 4.84588 21.8337 4.93352L26.3786 9.42241C26.5405 9.58221 26.5405 9.84001 26.3786 9.99981L20.3486 15.9554L20.2 16.1022V16.3111V23.1111C20.2 23.318 20.0247 23.5 19.8 23.5H0.9C0.675268 23.5 0.5 23.318 0.5 23.1111V0.888889C0.5 0.68198 0.675268 0.5 0.9 0.5H19.8C20.0247 0.5 20.2 0.681979 20.2 0.888889V4.75556V5.78786L21.01 5.14787L21.235 4.97009ZM1.3 22.2222V22.7222H1.8H18.355H18.855H18.9V22.2222H19.355V18.0444V16.8479L18.5036 17.6887L17.3336 18.8443C17.2449 18.9319 17.1452 18.9667 17.055 18.9667H17.0229L12.7296 18.7458C12.5202 18.7207 12.358 18.5555 12.3335 18.3593L12.1101 14.123C12.1132 14.0162 12.1622 13.891 12.2309 13.8229C12.231 13.8227 12.2312 13.8226 12.2314 13.8224L19.2502 6.93465L19.4 6.78765V6.57778V1.77778V1.27778H18.9H1.8H1.3V1.77778V22.2222ZM16.7118 18.1881L16.9351 18.2007L17.0933 18.0427L25.1483 9.99823L25.5045 9.64247L25.1463 9.2887L21.8614 6.04426L21.51 5.69725L21.1586 6.04426L13.0586 14.0443L12.8978 14.2031L12.9108 14.4289L13.0908 17.54L13.1166 17.9852L13.5618 18.0103L16.7118 18.1881ZM15.165 7.05556H5.535C5.31027 7.05556 5.135 6.87358 5.135 6.66667C5.135 6.45976 5.31027 6.27778 5.535 6.27778H15.165C15.3897 6.27778 15.565 6.45976 15.565 6.66667C15.565 6.87358 15.3897 7.05556 15.165 7.05556Z"
      fill="#979797"
      stroke="currentColor"
    />
  </svg>
);

BookAndPaper.propTypes = {
  className: PropTypes.string
};

BookAndPaper.defaultProps = {
  className: ''
};

export default BookAndPaper;
