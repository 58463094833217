import React from 'react';
import PropTypes from 'prop-types';

const CurveGraphIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.16992 1.00098V20.4218H19.9742"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M5.05469 14.7544C6.75693 14.5996 10.8114 13.764 13.4111 11.6594C16.0109 9.55479 17.0735 5.62413 17.2799 3.92188"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

CurveGraphIcon.propTypes = {
  className: PropTypes.string
};

CurveGraphIcon.defaultProps = {
  className: ''
};

export default CurveGraphIcon;
