import React from 'react';
import PropTypes from 'prop-types';

const Placeholder = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 95 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <path d="M47.4697 32.3231V62.8844" stroke="black" strokeWidth="3" />
      <rect
        x="2.20703"
        y="2.3407"
        width="90.526"
        height="90.526"
        stroke="black"
        strokeWidth="3"
        strokeDasharray="6 6"
      />
      <path
        d="M62.748 47.6038L32.1868 47.6038"
        stroke="black"
        strokeWidth="3"
      />
    </g>
  </svg>
);

Placeholder.propTypes = {
  className: PropTypes.string
};

Placeholder.defaultProps = {
  className: ''
};

export default Placeholder;
