import React from 'react';
import PropTypes from 'prop-types';

const QuoteClose = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.86581 5.72V9.848H11.9458V6.776C11.9458 5.56 11.7698 4.456 11.4178 3.464C11.0338 2.44 10.3938 1.48 9.49781 0.584L7.91381 1.736C8.55381 2.472 9.01781 3.16 9.30581 3.8C9.56181 4.408 9.72181 5.048 9.78581 5.72H7.86581ZM0.377812 5.72V9.848H4.45781V6.776C4.45781 5.56 4.28181 4.456 3.92981 3.464C3.54581 2.44 2.90581 1.48 2.00981 0.584L0.425812 1.736C1.06581 2.472 1.52981 3.16 1.81781 3.8C2.07381 4.408 2.23381 5.048 2.29781 5.72H0.377812Z"
      fill="currentColor"
    />
  </svg>
);

QuoteClose.propTypes = {
  className: PropTypes.string
};

QuoteClose.defaultProps = {
  className: ''
};

export default QuoteClose;
