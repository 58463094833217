import React from 'react';
import PropTypes from 'prop-types';

const ExportIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9765 0.145996H10.9765L13.2695 2.439L8.26953 7.439L9.68353 8.853L14.6835 3.853L16.9765 6.146V0.145996Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9766 16.146H1.97656C1.42456 16.146 0.976562 15.698 0.976562 15.146V1.146C0.976562 0.593996 1.42456 0.145996 1.97656 0.145996H8.97656V2.146H2.97656V14.146H14.9766V8.146H16.9766V15.146C16.9766 15.698 16.5286 16.146 15.9766 16.146Z"
      fill="currentColor"
    />
  </svg>
);

ExportIcon.propTypes = {
  className: PropTypes.string
};

ExportIcon.defaultProps = {
  className: ''
};

export default ExportIcon;
