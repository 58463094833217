import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const AndroidCircleIcon = ({ className, ...rest }) => (
  <Wrapper>
    <svg
      className={className}
      width={50}
      height={50}
      viewBox="0 0 50 50"
      {...rest}
    >
      <path d="M16.281.031c-.129.024-.261.047-.375.125-.457.309-.558.95-.25 1.407l2.156 3.218c-3.332 1.766-5.816 4.7-6.625 8.219h27.626c-.81-3.52-3.293-6.453-6.626-8.219l2.157-3.218c.308-.458.207-1.098-.25-1.407a1.025 1.025 0 00-1.407.281l-2.374 3.5A15.469 15.469 0 0025 3c-1.875 0-3.664.336-5.313.938l-2.375-3.5c-.23-.344-.648-.481-1.03-.407zM19.5 8a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm11 0c.832 0 1.5.672 1.5 1.5 0 .832-.668 1.5-1.5 1.5a1.5 1.5 0 010-3zM8 15a3 3 0 00-3 3v14a3 3 0 003 3c.352 0 .688-.074 1-.188V15.189A2.933 2.933 0 008 15zm3 0v22c0 1.652 1.348 3 3 3h22c1.652 0 3-1.348 3-3V15zm31 0c-.352 0-.688.074-1 .188v19.624c.313.11.648.188 1 .188a3 3 0 003-3V18a3 3 0 00-3-3zM15 42v4c0 2.207 1.793 4 4 4s4-1.793 4-4v-4zm12 0v4c0 2.207 1.793 4 4 4s4-1.793 4-4v-4z" />
    </svg>
  </Wrapper>
);

AndroidCircleIcon.propTypes = {
  className: PropTypes.any
};

const Wrapper = styled.div`
  border: 1px solid #000000;
  border-radius: 50%;
  display: inline-flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  svg {
    height: 50px;
  }
`;

export default AndroidCircleIcon;
