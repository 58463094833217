import React from 'react';
import PropTypes from 'prop-types';

const ClearIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2217 0.743408L0.999854 10.9652"
      stroke="#AC1B1B"
      strokeWidth="2"
    />
    <path
      d="M11.2217 11.2568L0.999855 1.03501"
      stroke="#AC1B1B"
      strokeWidth="2"
    />
  </svg>
);

ClearIcon.propTypes = {
  className: PropTypes.string
};

ClearIcon.defaultProps = {
  className: ''
};

export default ClearIcon;
