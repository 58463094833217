import React from 'react';
import PropTypes from 'prop-types';

const Check = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22266 18.964L0.222656 10.964L3.22266 7.96399L8.22266 12.964L20.2227 0.963989L23.2227 3.96399L8.22266 18.964Z"
      fill="currentColor"
    />
  </svg>
);

Check.propTypes = {
  className: PropTypes.string
};

Check.defaultProps = {
  className: ''
};

export default Check;
