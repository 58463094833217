import React from 'react';
import PropTypes from 'prop-types';

const ListIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.97656 0H1.97656C1.42356 0 0.976562 0.447 0.976562 1V5C0.976562 5.553 1.42356 6 1.97656 6H5.97656C6.52956 6 6.97656 5.553 6.97656 5V1C6.97656 0.447 6.52956 0 5.97656 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.97656 8H1.97656C1.42356 8 0.976562 8.447 0.976562 9V13C0.976562 13.553 1.42356 14 1.97656 14H5.97656C6.52956 14 6.97656 13.553 6.97656 13V9C6.97656 8.447 6.52956 8 5.97656 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.97656 16H1.97656C1.42356 16 0.976562 16.447 0.976562 17V21C0.976562 21.553 1.42356 22 1.97656 22H5.97656C6.52956 22 6.97656 21.553 6.97656 21V17C6.97656 16.447 6.52956 16 5.97656 16Z"
      fill="currentColor"
    />
    <rect x="8.97656" y="2" width="14" height="2" fill="currentColor" />
    <rect x="8.97656" y="10" width="14" height="2" fill="currentColor" />
    <rect x="8.97656" y="18" width="14" height="2" fill="currentColor" />
  </svg>
);

ListIcon.propTypes = {
  className: PropTypes.string
};

ListIcon.defaultProps = {
  className: ''
};

export default ListIcon;
