import React from 'react';
import PropTypes from 'prop-types';

const ArrowDown = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.205692 1.97259L1.61969 0.558593L5.61969 4.55859L9.61969 0.558594L11.0337 1.97259L6.32669 6.67959C5.93569 7.07059 5.30369 7.07059 4.91269 6.67959L0.205692 1.97259Z"
      fill="currentColor"
    />
  </svg>
);

ArrowDown.propTypes = {
  className: PropTypes.string
};

ArrowDown.defaultProps = {
  className: ''
};

export default ArrowDown;
