import React from 'react';
import PropTypes from 'prop-types';

const PencilIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0H15C15.553 0 16 0.448 16 1V15C16 15.242 15.912 15.476 15.753 15.659L8.753 23.659C8.562 23.875 8.288 24 8 24C7.712 24 7.438 23.875 7.247 23.659L0.247 15.659C0.088 15.476 0 15.242 0 15V1C0 0.448 0.447 0 1 0H4V9H6V0H10V9H12V0ZM2.463 15.153L6.704 20H9.296L13.538 15.152L11.094 13.32L8.707 15.707C8.512 15.902 8.256 16 8 16C7.744 16 7.488 15.902 7.294 15.708L4.907 13.321L2.463 15.153Z"
      fill="currentColor"
    />
  </svg>
);

PencilIcon.propTypes = {
  className: PropTypes.string
};

PencilIcon.defaultProps = {
  className: ''
};

export default PencilIcon;
