import React from 'react';
import PropTypes from 'prop-types';

const GlyphsPerformance = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      stroke="#607187"
      strokeWidth="2"
      d="M8.264 12.636c-.236.71-.354 1.537-.354 2.364M22.091 15c0-3.9-3.19-7.09-7.09-7.09-.828 0-1.655.117-2.364.354"
      opacity=".5"
    />
    <path
      stroke="#607187"
      strokeLinecap="square"
      strokeWidth="2"
      d="M13.346 13.345L9.092 9.091"
    />
    <path
      fill="#607187"
      fillRule="evenodd"
      d="M15 17.364a2.364 2.364 0 1 0 0-4.728 2.364 2.364 0 0 0 0 4.728z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke="#607187"
      strokeLinecap="square"
      strokeWidth="2"
      d="M15 28c7.18 0 13-5.82 13-13S22.18 2 15 2 2 7.82 2 15s5.82 13 13 13z"
      clipRule="evenodd"
    />
  </svg>
);

GlyphsPerformance.propTypes = {
  className: PropTypes.string
};

GlyphsPerformance.defaultProps = {
  className: ''
};

export default GlyphsPerformance;
