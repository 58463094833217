import React from 'react';
import PropTypes from 'prop-types';

const AidingInClassLearningIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="2"
      d="M23 4h-4v10l-4-3-4 3V4H7a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3z"
      clipRule="evenodd"
    />
  </svg>
);

AidingInClassLearningIcon.propTypes = {
  className: PropTypes.string
};

AidingInClassLearningIcon.defaultProps = {
  className: ''
};

export default AidingInClassLearningIcon;
