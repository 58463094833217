import React from 'react';
import PropTypes from 'prop-types';

const ArrowIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 5 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L4 4L1 7" stroke="currentColor" strokeLinecap="square" />
  </svg>
);

ArrowIcon.propTypes = {
  className: PropTypes.string
};

ArrowIcon.defaultProps = {
  className: ''
};

export default ArrowIcon;
