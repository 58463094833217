import React from 'react';

const HomeIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M15.084 5.48L8.417.146a.667.667 0 00-.833 0L.917 5.48a.667.667 0 00-.25.52v8.667a.667.667 0 00.667.667H6V10h4v5.334h4.667a.667.667 0 00.667-.667V6a.668.668 0 00-.25-.52z"
      fill="#242C34"
    />
  </svg>
);

export default HomeIcon;
