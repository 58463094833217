import React from 'react';
import PropTypes from 'prop-types';

const Pen = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.933 6.07L3.61 8.442 1.679 20.999l12.557-1.932 2.372-6.322M1.679 21L8.04 14.64"
      stroke="#fff"
      strokeWidth="2"
    />
    <path
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="square"
      d="M8.44 4.578l2.898-2.897 9.659 9.659-2.898 2.897z"
    />
    <path
      clipRule="evenodd"
      d="M10.773 14.637a1.932 1.932 0 1 0-2.733-2.732 1.932 1.932 0 0 0 2.733 2.732z"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

Pen.propTypes = {
  className: PropTypes.string
};

Pen.defaultProps = {
  className: ''
};

export default Pen;
