import React from 'react';
import PropTypes from 'prop-types';

const Instagram = ({ className, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
    className={className}
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.58095 0.0467692C5.41256 0.00876923 5.67808 0 7.79487 0C9.91167 0 10.1772 0.00876923 11.0088 0.0467692C12.2759 0.104744 13.3887 0.415077 14.2817 1.30808C15.1747 2.20156 15.485 3.31428 15.543 4.58095C15.581 5.41256 15.5897 5.67808 15.5897 7.79487C15.5897 9.91167 15.581 10.1772 15.543 11.0088C15.485 12.2759 15.1747 13.3887 14.2817 14.2817C13.3882 15.1747 12.2755 15.485 11.0088 15.543C10.1772 15.581 9.91167 15.5897 7.79487 15.5897C5.67808 15.5897 5.41256 15.581 4.58095 15.543C3.3138 15.485 2.20108 15.1747 1.30808 14.2817C0.415077 13.3882 0.104744 12.2755 0.0467692 11.0088C0.00876923 10.1772 0 9.91167 0 7.79487C0 5.67808 0.00876923 5.41256 0.0467692 4.58095C0.104744 3.3138 0.415077 2.20108 1.30808 1.30808C2.20156 0.415077 3.31428 0.104744 4.58095 0.0467692ZM10.9445 1.44985C10.1226 1.41233 9.8761 1.40454 7.79487 1.40454C5.71364 1.40454 5.46713 1.41233 4.64526 1.44985C3.7581 1.49028 2.93477 1.6681 2.30144 2.30144C1.6681 2.93477 1.49028 3.7581 1.44985 4.64526C1.41233 5.46713 1.40454 5.71364 1.40454 7.79487C1.40454 9.8761 1.41233 10.1226 1.44985 10.9445C1.49028 11.8316 1.6681 12.655 2.30144 13.2883C2.93477 13.9216 3.7581 14.0995 4.64526 14.1399C5.46713 14.1774 5.71364 14.1852 7.79487 14.1852C9.8761 14.1852 10.1226 14.1774 10.9445 14.1399C11.8316 14.0995 12.655 13.9216 13.2883 13.2883C13.9216 12.655 14.0995 11.8316 14.1399 10.9445C14.1774 10.1226 14.1852 9.8761 14.1852 7.79487C14.1852 5.71364 14.1774 5.46713 14.1399 4.64526C14.0995 3.7581 13.9216 2.93477 13.2883 2.30144C12.655 1.6681 11.8316 1.49028 10.9445 1.44985Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.69238 7.69505C3.69238 5.48472 5.48423 3.69238 7.69505 3.69238C9.90587 3.69238 11.6977 5.48472 11.6977 7.69505C11.6977 9.90538 9.90538 11.6977 7.69505 11.6977C5.48472 11.6977 3.69238 9.90538 3.69238 7.69505ZM5.09692 7.69505C5.09692 9.12979 6.25982 10.2932 7.69505 10.2932C9.13028 10.2932 10.2932 9.13028 10.2932 7.69505C10.2932 6.26031 9.13028 5.09692 7.69505 5.09692C6.26031 5.09692 5.09692 6.25982 5.09692 7.69505Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0125 5.56315C12.5291 5.56315 12.9479 5.14437 12.9479 4.62777C12.9479 4.11117 12.5291 3.69238 12.0125 3.69238C11.4959 3.69238 11.0771 4.11117 11.0771 4.62777C11.0771 5.14437 11.4959 5.56315 12.0125 5.56315Z"
    />
  </svg>
);

Instagram.propTypes = {
  className: PropTypes.string
};

Instagram.defaultProps = {
  className: ''
};

export default Instagram;
