import * as React from 'react';

function IconUpdate(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 7.5H3.17422C3.18339 7.5002 3.19254 7.5002 3.20167 7.5H5C5.34518 7.5 5.625 7.22018 5.625 6.875C5.625 6.52982 5.34518 6.25 5 6.25H4.24527C5.47202 4.36837 7.59002 3.125 10 3.125C13.7973 3.125 16.875 6.20268 16.875 10C16.875 10.3452 17.1548 10.625 17.5 10.625C17.8452 10.625 18.125 10.3452 18.125 10C18.125 5.51232 14.4877 1.875 10 1.875C7.09961 1.875 4.56094 3.39772 3.125 5.68177V4.375C3.125 4.02982 2.84518 3.75 2.5 3.75C2.15482 3.75 1.875 4.02982 1.875 4.375V6.875C1.875 7.22018 2.15482 7.5 2.5 7.5ZM15 12.5C14.6548 12.5 14.375 12.7798 14.375 13.125C14.375 13.4702 14.6548 13.75 15 13.75H15.7547C14.528 15.6316 12.41 16.875 10 16.875C6.20268 16.875 3.125 13.7973 3.125 10C3.125 9.65482 2.84518 9.375 2.5 9.375C2.15482 9.375 1.875 9.65482 1.875 10C1.875 14.4877 5.51232 18.125 10 18.125C12.9004 18.125 15.4391 16.6023 16.875 14.3182V15.625C16.875 15.9702 17.1548 16.25 17.5 16.25C17.8452 16.25 18.125 15.9702 18.125 15.625V13.125C18.125 12.7798 17.8452 12.5 17.5 12.5H16.8258C16.8166 12.4998 16.8075 12.4998 16.7983 12.5H15ZM13.3489 8.44365C13.5929 8.19957 13.5929 7.80384 13.3489 7.55977C13.1048 7.31569 12.709 7.31569 12.465 7.55977L8.83105 11.1937L7.47807 9.84072C7.234 9.59664 6.83827 9.59664 6.59419 9.84072C6.35011 10.0848 6.35011 10.4805 6.59419 10.7246L8.40078 12.5312C8.64485 12.7753 9.04058 12.7753 9.28465 12.5312C9.31952 12.4963 9.3494 12.4584 9.37431 12.4182L13.3489 8.44365Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconUpdate);
