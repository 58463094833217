import React from 'react';
import PropTypes from 'prop-types';

const Up = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 8L6.82353 3L1 8" stroke="currentColor" strokeWidth="3" />
  </svg>
);

Up.propTypes = {
  className: PropTypes.string
};

Up.defaultProps = {
  className: ''
};

export default Up;
