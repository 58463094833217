import React from 'react';
import PropTypes from 'prop-types';

const Message = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.579 0H2.126A2.12 2.12 0 0 0 0 2.126v11.516a2.12 2.12 0 0 0 2.126 2.126h4.19l1.979 3.348c.337.568.926.884 1.558.884.631 0 1.242-.337 1.558-.884l1.979-3.348h4.189a2.12 2.12 0 0 0 2.126-2.126V2.126A2.12 2.12 0 0 0 17.58 0zm.61 13.642c0 .337-.273.59-.589.59h-4.632a.743.743 0 0 0-.652.379l-2.21 3.726c-.085.126-.19.147-.253.147-.064 0-.169-.02-.253-.147L7.39 14.61a.77.77 0 0 0-.653-.38h-4.61a.588.588 0 0 1-.59-.589V2.126c0-.337.274-.59.59-.59h15.452c.337 0 .59.275.59.59v11.516h.02z"
      fill="#fff"
    />
    <path
      d="M7.157 4.59c-.505 0-.947.19-1.326.568-.379.38-.568.8-.568 1.284 0 .464.147.864.463 1.243.316.379.82.463 1.242.484-.063.59-.4 1.22-.737 1.663-.042.063-.105.126-.168.21a.285.285 0 0 0 0 .358l.694.737a.267.267 0 0 0 .38 0c.294-.316.568-.694.841-1.095C8.673 8.97 9.01 7.875 9.01 6.78c0-.631-.19-1.158-.568-1.558a1.537 1.537 0 0 0-1.285-.631zM12.548 4.59c-.506 0-.948.19-1.327.568-.379.38-.568.8-.568 1.284 0 .464.147.864.463 1.243.316.379.821.463 1.242.484-.063.59-.4 1.22-.737 1.663-.042.063-.105.126-.168.21a.285.285 0 0 0 0 .358l.695.737a.267.267 0 0 0 .379 0c.294-.316.568-.694.842-1.095C14.063 8.97 14.4 7.875 14.4 6.78c0-.631-.19-1.158-.568-1.558a1.537 1.537 0 0 0-1.284-.631z"
      fill="#fff"
    />
  </svg>
);

Message.propTypes = {
  className: PropTypes.string
};

Message.defaultProps = {
  className: ''
};

export default Message;
