import React from 'react';
import PropTypes from 'prop-types';

const UpArrowIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    width="60"
    height="63"
    viewBox="0 0 60 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.4287 35.7559L30.8573 35.7559L30.8573 64.7018L15.4287 64.7018L15.4287 35.7559Z"
        fill="#D22222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14966 36.8477C3.79224 37.1989 3.68482 37.7266 3.87975 38.1858C4.07469 38.6448 4.52958 38.9444 5.03437 38.9444L18.7273 38.9444L18.7273 69.6318C18.7273 70.3094 19.2871 70.8594 19.9769 70.8594L39.9702 70.8594C40.66 70.8594 41.2199 70.3094 41.2199 69.6318L41.2199 38.9443L54.9652 38.9443C55.47 38.9443 55.9249 38.6447 56.1198 38.1881C56.3123 37.729 56.2074 37.2012 55.8499 36.8501L30.9208 12.2804C30.6859 12.0497 30.3684 11.9195 30.0361 11.9195C29.7037 11.9195 29.3862 12.0497 29.1513 12.278L4.14966 36.8477ZM10.3561 35.539L22.1558 35.539L22.1558 67.454L37.7913 67.454L37.7913 35.5389L49.6519 35.5389L30.0326 16.2025L10.3561 35.539Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0.998047L56.0693 26.8913L53.645 29.2992L30 5.81402L6.35503 29.2992L3.93066 26.8913L30 0.998047Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="60"
          height="63"
          fill="white"
          transform="translate(60 63) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);

UpArrowIcon.propTypes = {
  className: PropTypes.string
};

UpArrowIcon.defaultProps = {
  className: ''
};

export default UpArrowIcon;
