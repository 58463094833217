import React from 'react';
import PropTypes from 'prop-types';

const Sample = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 25"
  >
    <path
      fill="currentColor"
      d="M24.059 4.727a4.098 4.098 0 0 0-1.21-2.576 4.094 4.094 0 0 0-2.574-1.21 3.178 3.178 0 0 0-2.54.899L2.744 16.83a1.97 1.97 0 0 0-.515.95L.942 23.286a.644.644 0 0 0 .772.773l5.504-1.288a1.97 1.97 0 0 0 .95-.515L23.16 7.271a3.188 3.188 0 0 0 .898-2.544zm-4.45 4.277a5.83 5.83 0 0 0-3.604-3.605l.88-.881a4.548 4.548 0 0 1 3.604 3.605l-.88.881zM8.109 20.51a5.742 5.742 0 0 0-.961-1.746L17.732 8.18c.415.553.698 1.193.829 1.872L8.108 20.51zm6.843-14.068c.68.13 1.32.412 1.874.826L6.245 17.86a5.742 5.742 0 0 0-1.746-.955L14.952 6.442zM2.713 21.362c.37.246.688.563.934.933l-1.215.28.281-1.213zm4.219.17l-1.972.448a4.62 4.62 0 0 0-1.93-1.928l.458-1.976a.718.718 0 0 1 .041-.108 4.458 4.458 0 0 1 3.51 3.526.72.72 0 0 1-.107.041v-.003zM22.25 6.362l-.71.71a5.83 5.83 0 0 0-3.604-3.605l.71-.71a1.888 1.888 0 0 1 1.359-.534 2.785 2.785 0 0 1 1.936.85c.988.99 1.13 2.467.309 3.289zM5.691 11.111a.646.646 0 0 0 .634-.77.643.643 0 0 0-.177-.329l-.87-.867 1.326-1.323a.644.644 0 1 0-.913-.912L4.365 8.233l-1.001-.998L4.69 5.912a.644.644 0 0 0-.457-1.1.647.647 0 0 0-.456.188L2.45 6.323l-1.143-1.14a.067.067 0 0 1 0-.093l3.796-3.786a.067.067 0 0 1 .093 0l4.84 4.829a.646.646 0 0 0 1.075-.462.643.643 0 0 0-.164-.447L6.11.395a1.359 1.359 0 0 0-1.917 0L.396 4.182a1.352 1.352 0 0 0 0 1.912l4.841 4.827a.643.643 0 0 0 .454.19zM18.84 14.078a.647.647 0 0 0-1.103.455c0 .171.068.335.189.456l4.843 4.827a.067.067 0 0 1 0 .093l-3.796 3.787a.067.067 0 0 1-.092 0l-1.146-1.143 1.326-1.324a.644.644 0 1 0-.913-.911l-1.327 1.323-1-.999 1.326-1.323a.643.643 0 0 0-.457-1.1.647.647 0 0 0-.457.189l-1.326 1.323-.867-.865a.646.646 0 0 0-1.075.462.642.642 0 0 0 .164.447l4.838 4.83a1.358 1.358 0 0 0 1.917 0l3.796-3.788a1.353 1.353 0 0 0 0-1.912l-4.84-4.828z"
    />
  </svg>
);

Sample.propTypes = {
  className: PropTypes.string
};

Sample.defaultProps = {
  className: ''
};

export default Sample;
