import React from 'react';
import PropTypes from 'prop-types';

const ReviewsResult = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M20.4 16.8A8.215 8.215 0 0 0 23 11c0-5-4.9-9-11-9S1 6 1 11s4.9 9 11 9a10.551 10.551 0 0 0 3.1-.4L21 22l-.6-5.2z"
      clipRule="evenodd"
    />
  </svg>
);

ReviewsResult.propTypes = {
  className: PropTypes.string
};

ReviewsResult.defaultProps = {
  className: ''
};

export default ReviewsResult;
