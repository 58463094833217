import React from 'react';
import PropTypes from 'prop-types';

const LinkedinIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.64921 0.485352C0.810132 0.485352 0.126953 1.16853 0.126953 2.00761C0.126953 2.8467 0.810132 3.52987 1.64921 3.52987C2.4883 3.52987 3.17148 2.8467 3.17148 2.00761C3.17148 1.16853 2.4883 0.485352 1.64921 0.485352ZM8.84536 4.08342C7.83386 4.08342 7.22534 4.58507 6.76955 5.09971V4.49859C6.76954 4.42613 6.70362 4.36021 6.63116 4.3602H4.41696C4.34451 4.36021 4.27858 4.42613 4.27857 4.49859V11.9715C4.27858 12.044 4.34451 12.1099 4.41696 12.1099H6.76955C6.84201 12.1099 6.90793 12.044 6.90794 11.9715V7.81988C6.90794 7.44157 7.02526 7.08632 7.20633 6.83387C7.38741 6.58143 7.62335 6.43601 7.87665 6.43601H8.15342C8.40672 6.43601 8.64266 6.58143 8.82374 6.83387C9.00481 7.08632 9.12213 7.44157 9.12213 7.81988V11.9715C9.12214 12.044 9.18806 12.1099 9.26052 12.1099H11.6131C11.6856 12.1099 11.7515 12.044 11.7515 11.9715V7.12795C11.7515 6.22305 11.5779 5.45898 11.1158 4.91807C10.6537 4.37716 9.91324 4.08342 8.84536 4.08342ZM0.516168 4.3602C0.452731 4.3723 0.40259 4.43401 0.403728 4.49859V11.9715C0.403735 12.044 0.469658 12.1099 0.542115 12.1099H2.8947C2.96716 12.1099 3.03308 12.044 3.03309 11.9715V4.49859C3.03308 4.42613 2.96716 4.36021 2.8947 4.3602C2.1027 4.3602 1.30618 4.3602 0.516168 4.3602Z"
      fill="white"
    />
  </svg>
);

LinkedinIcon.propTypes = {
  className: PropTypes.string
};

LinkedinIcon.defaultProps = {
  className: ''
};

export default LinkedinIcon;
