import React from 'react';
import PropTypes from 'prop-types';

const OurFeatureIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 29 30"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 0v30l14.017-13.045L28.033 30V0H0z"
      clipRule="evenodd"
    />
  </svg>
);

OurFeatureIcon.propTypes = {
  className: PropTypes.string
};

OurFeatureIcon.defaultProps = {
  className: ''
};

export default OurFeatureIcon;
