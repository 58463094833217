import React from 'react';
import PropTypes from 'prop-types';

const GlyphsWorldClass = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      stroke="#607187"
      strokeLinecap="square"
      strokeWidth="2"
      d="M14 25a9 9 0 1 0 0-18 9 9 0 0 0 0 18z"
      clipRule="evenodd"
    />
    <path
      stroke="#607187"
      strokeLinecap="square"
      strokeWidth="2"
      d="M24.955 21.005c1.089 2.145 1.378 3.816.622 4.572C23.92 27.234 17.799 23.799 12 18 6.2 12.201 2.766 6.08 4.423 4.423c.757-.757 2.43-.467 4.577.624"
    />
  </svg>
);

GlyphsWorldClass.propTypes = {
  className: PropTypes.string
};

GlyphsWorldClass.defaultProps = {
  className: ''
};

export default GlyphsWorldClass;
