import React from 'react';
import PropTypes from 'prop-types';

const ChatBalloonIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5699 16.2219C22.1797 14.7157 23.1166 12.6258 23.1699 10.4219C23.1699 5.42188 18.2699 1.42188 12.1699 1.42188C6.06992 1.42188 1.16992 5.42188 1.16992 10.4219C1.16992 15.4219 6.06992 19.4219 12.1699 19.4219C13.2174 19.4432 14.2622 19.3084 15.2699 19.0219L21.1699 21.4219L20.5699 16.2219Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

ChatBalloonIcon.propTypes = {
  className: PropTypes.string
};

ChatBalloonIcon.defaultProps = {
  className: ''
};

export default ChatBalloonIcon;
