import React from 'react';
import PropTypes from 'prop-types';

const Star = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1428 4.96525L10.8267 4.34863L8.90012 0.508474C8.71381 0.193686 8.37106 0 8.00032 0C7.62958 0 7.28683 0.193686 7.10051 0.508474L5.17393 4.34961L0.857874 4.96525C0.480829 5.01836 0.167268 5.27726 0.0492386 5.63294C-0.0687909 5.98861 0.0292192 6.37925 0.301995 6.64035L3.42431 9.63019L2.68748 13.8523C2.62321 14.2206 2.77744 14.5929 3.08532 14.8125C3.3932 15.0322 3.80134 15.0611 4.13816 14.8872L8.00032 12.893L11.8605 14.8852C12.1973 15.0591 12.6054 15.0302 12.9133 14.8106C13.2212 14.5909 13.3754 14.2187 13.3112 13.8503L12.5743 9.62822L15.6986 6.64035C15.9707 6.37945 16.0685 5.98955 15.951 5.63437C15.8335 5.27919 15.521 5.02023 15.1448 4.96623L15.1428 4.96525Z"
      fill="currentColor"
    />
  </svg>
);

Star.propTypes = {
  className: PropTypes.string
};

Star.defaultProps = {
  className: ''
};

export default Star;
