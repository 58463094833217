import * as React from 'react';

const IconCalendarSchedule = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.6317 1.99077H16.6774V1.0364C16.6774 0.509316 16.2501 0.0820312 15.723 0.0820312C15.1959 0.0820312 14.7686 0.509317 14.7686 1.0364V1.99077H5.22496V1.0364C5.22496 0.509316 4.79768 0.0820312 4.2706 0.0820312C3.74351 0.0820312 3.31623 0.509317 3.31623 1.0364V1.99077H2.36186C1.31206 1.99077 0.453125 2.8497 0.453125 3.8995L0.453125 18.0865C0.453125 19.1363 1.31206 19.9953 2.36186 19.9953H17.6317C18.6816 19.9953 19.5405 19.1363 19.5405 18.0865V3.8995C19.5405 2.8497 18.6816 1.99077 17.6317 1.99077ZM17.6317 18.0865H2.36186V8.67134H17.6317V18.0865ZM17.6317 6.76261H2.36186V3.8995H17.6317V6.76261Z"
      fill="currentColor"
    />
  </svg>
);

export default IconCalendarSchedule;
