import React from 'react';
import PropTypes from 'prop-types';

const Church = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11H13V8L9 4.8V3H11V0H7V4.8L3 8V11H0V16H6V12H10V16H16V11Z"
      fill="currentColor"
    />
  </svg>
);

Church.propTypes = {
  className: PropTypes.string
};

Church.defaultProps = {
  className: ''
};

export default Church;
