import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1006 10.1005L29.8996 29.8995"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M29.8996 10.1005L10.1006 29.8995"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

CloseIcon.propTypes = {
  className: PropTypes.string
};

CloseIcon.defaultProps = {
  className: ''
};

export default CloseIcon;
