import React from 'react';
import PropTypes from 'prop-types';

const DownLoadPdfIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 17.5H13" stroke="#D22222" strokeWidth="2" />
    <path d="M7 0V14M7 14L1 9.5M7 14L13 9.5" stroke="#D22222" strokeWidth="2" />
  </svg>
);

DownLoadPdfIcon.propTypes = {
  className: PropTypes.string
};

DownLoadPdfIcon.defaultProps = {
  className: ''
};

export default DownLoadPdfIcon;
