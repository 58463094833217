import React from 'react';
import PropTypes from 'prop-types';

const Document = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9766 7C14.9766 7.552 15.4246 8 15.9766 8H22.9766V23C22.9766 23.552 22.5286 24 21.9766 24H1.97656C1.42456 24 0.976562 23.552 0.976562 23V1C0.976562 0.448 1.42456 0 1.97656 0H14.9766V7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3906 6H16.9766V0.585999L22.3906 6Z"
      fill="currentColor"
    />
  </svg>
);

Document.propTypes = {
  className: PropTypes.string
};

Document.defaultProps = {
  className: ''
};

export default Document;
