import React from 'react';
import PropTypes from 'prop-types';

const PaperAirPlane = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.126 26.9906L27.251 0.990601L0.250977 10.7406L17.126 26.9906ZM4.31335 11.5878L20.861 5.6121L9.48385 16.5679L4.31335 11.5878ZM16.2462 23.0798L11.0712 18.0964L22.4517 7.14393L16.2462 23.0798Z"
      fill="currentColor"
    />
    <path
      d="M2.83023 16.4753L0.580566 18.6416L2.17156 20.1737L4.42122 18.0073L2.83023 16.4753Z"
      fill="currentColor"
    />
    <path
      d="M9.5805 22.9753L7.33084 25.1416L8.92183 26.6737L11.1715 24.5073L9.5805 22.9753Z"
      fill="currentColor"
    />
    <path
      d="M6.20453 19.7247L0.57959 25.1414L2.17058 26.6734L7.79552 21.2568L6.20453 19.7247Z"
      fill="currentColor"
    />
  </svg>
);

PaperAirPlane.propTypes = {
  className: PropTypes.string
};

PaperAirPlane.defaultProps = {
  className: ''
};

export default PaperAirPlane;
