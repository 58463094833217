import React from 'react';
import PropTypes from 'prop-types';

const Chat = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 4v13h-4v4l-6-4h-1"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      clipRule="evenodd"
      d="M19 1H1v12h4v5l7-5h7V1z"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

Chat.propTypes = {
  className: PropTypes.string
};

Chat.defaultProps = {
  className: ''
};

export default Chat;
