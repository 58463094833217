import React from 'react';

const TriangleUp = props => (
  <svg width="1em" height="1em" viewBox="0 0 8 4" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0L0 4h8L4 0z"
      fill="#C3453E"
    />
  </svg>
);

export default TriangleUp;
