import React from 'react';
import PropTypes from 'prop-types';

const QuoteOpen = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.64981 5.72V9.848H0.569813V6.776C0.569813 5.56 0.745813 4.456 1.09781 3.464C1.48181 2.44 2.12181 1.48 3.01781 0.584L4.60181 1.736C3.96181 2.472 3.49781 3.16 3.20981 3.8C2.95381 4.408 2.79381 5.048 2.72981 5.72H4.64981ZM12.1378 5.72V9.848H8.05781V6.776C8.05781 5.56 8.23381 4.456 8.58581 3.464C8.96981 2.44 9.60981 1.48 10.5058 0.584L12.0898 1.736C11.4498 2.472 10.9858 3.16 10.6978 3.8C10.4418 4.408 10.2818 5.048 10.2178 5.72H12.1378Z"
      fill="currentColor"
    />
  </svg>
);

QuoteOpen.propTypes = {
  className: PropTypes.string
};

QuoteOpen.defaultProps = {
  className: ''
};

export default QuoteOpen;
