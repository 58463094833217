import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2227 22.964C18.1932 22.964 22.2227 18.9346 22.2227 13.964C22.2227 8.99343 18.1932 4.96399 13.2227 4.96399C8.25209 4.96399 4.22266 8.99343 4.22266 13.964C4.22266 18.9346 8.25209 22.964 13.2227 22.964Z"
      fill="#D8D8D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9834 10.2037C16.6639 9.88432 16.146 9.88432 15.8265 10.2037L13.1322 12.898L10.438 10.2037C10.1169 9.89366 9.60662 9.89809 9.29102 10.2137C8.97542 10.5293 8.97098 11.0396 9.28105 11.3606L11.9753 14.0549L9.28105 16.7492C9.06836 16.9546 8.98306 17.2588 9.05794 17.5449C9.13281 17.8309 9.35621 18.0543 9.64227 18.1292C9.92833 18.2041 10.2325 18.1188 10.438 17.9061L13.1322 15.2118L15.8265 17.9061C16.1475 18.2162 16.6578 18.2117 16.9734 17.8961C17.289 17.5805 17.2935 17.0702 16.9834 16.7492L14.2891 14.0549L16.9834 11.3606C17.3028 11.0411 17.3028 10.5232 16.9834 10.2037Z"
      fill="#B6B6B6"
    />
  </svg>
);

Close.propTypes = {
  className: PropTypes.string
};

Close.defaultProps = {
  className: ''
};

export default Close;
