import * as React from 'react';

function IconCourseArrowRight(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15902 11.7745C5.90176 12.0523 5.90176 12.4814 6.15902 12.7592C6.44585 13.069 6.93571 13.069 7.22254 12.7592L11.0699 8.60405C11.3854 8.26326 11.3854 7.737 11.0699 7.39621L7.22255 3.24112C6.93572 2.93135 6.44588 2.93135 6.15904 3.24112C5.90178 3.51896 5.90178 3.94802 6.15904 4.22586L9.65376 8.00013L6.15902 11.7745Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconCourseArrowRight);
