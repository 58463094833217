import PropTypes from 'prop-types';
import React from 'react';

const ExitModalIcon = ({ className, color = '#D22222', ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
    className={className}
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
  >
    <path
      d="M35.7561 18.6001C35.7561 8.54646 27.7981 0.380859 18.0001 0.380859C8.20214 0.380859 0.244141 8.54646 0.244141 18.6001C0.244141 28.6537 8.20214 36.8193 18.0001 36.8193C27.7981 36.8193 35.7561 28.6537 35.7561 18.6001ZM4.15414 18.6001C4.15414 10.7649 10.3641 4.39286 18.0001 4.39286C25.6361 4.39286 31.8461 10.7649 31.8461 18.6001C31.8461 26.4353 25.6361 32.8073 18.0001 32.8073C10.3641 32.8073 4.15414 26.4353 4.15414 18.6001Z"
      fill={color}
    />
    <path
      d="M18.0003 27.1904C18.9911 27.1904 19.7943 26.3662 19.7943 25.3496C19.7943 24.3329 18.9911 23.5088 18.0003 23.5088C17.0095 23.5088 16.2063 24.3329 16.2063 25.3496C16.2063 26.3662 17.0095 27.1904 18.0003 27.1904Z"
      fill={color}
    />
    <path
      d="M18.0001 20.2992C19.0581 20.2992 19.9321 19.4024 19.9321 18.3168V10.3872C19.9321 9.30158 19.0581 8.40479 18.0001 8.40479C16.9421 8.40479 16.0681 9.30158 16.0681 10.3872V18.2696C16.0681 19.4024 16.9421 20.2992 18.0001 20.2992Z"
      fill={color}
    />
  </svg>
);

ExitModalIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

ExitModalIcon.defaultProps = {
  className: ''
};

export default ExitModalIcon;
