import React from 'react';
import PropTypes from 'prop-types';

const ColumnIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9766 10.7754V2.77539C22.9766 1.67082 22.0811 0.775391 20.9766 0.775391H12.9766V10.7754H22.9766Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9766 0.775391H2.97656C1.87199 0.775391 0.976563 1.67082 0.976562 2.77539V10.7754H10.9766V0.775391Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.976562 12.7754V20.7754C0.976563 21.88 1.87199 22.7754 2.97656 22.7754H10.9766V12.7754H0.976562Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9766 22.7754H20.9766C22.0811 22.7754 22.9766 21.88 22.9766 20.7754V12.7754H12.9766V22.7754Z"
      fill="currentColor"
    />
  </svg>
);

ColumnIcon.propTypes = {
  className: PropTypes.string
};

ColumnIcon.defaultProps = {
  className: ''
};

export default ColumnIcon;
