import PropTypes from 'prop-types';
import React from 'react';

const ExitIcon = ({ className, color = 'black', ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
    className={className}
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M5.53809 7.49986C5.53809 7.66562 5.60292 7.82459 5.71832 7.9418C5.83373 8.05901 5.99025 8.12486 6.15345 8.12486H13.8978L12.4871 9.55767C12.4299 9.61574 12.3846 9.68468 12.3536 9.76055C12.3227 9.83642 12.3068 9.91774 12.3068 9.99986C12.3068 10.082 12.3227 10.1633 12.3536 10.2392C12.3846 10.315 12.4299 10.384 12.4871 10.442C12.6026 10.5593 12.7592 10.6252 12.9225 10.6252C13.0033 10.6252 13.0834 10.609 13.1581 10.5776C13.2328 10.5462 13.3007 10.5001 13.3579 10.442L15.8193 7.94205C15.8765 7.884 15.9219 7.81507 15.9529 7.7392C15.9839 7.66332 15.9998 7.58199 15.9998 7.49986C15.9998 7.41772 15.9839 7.3364 15.9529 7.26052C15.9219 7.18465 15.8765 7.11572 15.8193 7.05767L13.3579 4.55767C13.3007 4.4996 13.2328 4.45354 13.1581 4.42211C13.0834 4.39069 13.0033 4.37451 12.9225 4.37451C12.7592 4.37451 12.6026 4.4404 12.4871 4.55767C12.4299 4.61574 12.3846 4.68468 12.3536 4.76055C12.3227 4.83642 12.3068 4.91774 12.3068 4.99986C12.3068 5.16571 12.3716 5.32477 12.4871 5.44205L13.8978 6.87486H6.15345C5.99025 6.87486 5.83373 6.94071 5.71832 7.05792C5.60292 7.17513 5.53809 7.3341 5.53809 7.49986Z"
      fill={color}
    />
    <path
      d="M0.000203133 13.125C0.000203133 13.6223 0.194702 14.0992 0.540913 14.4508C0.887123 14.8025 1.35669 15 1.8463 15L8.61533 15C9.10494 15 9.57451 14.8025 9.92072 14.4508C10.2669 14.0992 10.4614 13.6223 10.4614 13.125V10.625C10.4614 10.4592 10.3966 10.3003 10.2812 10.1831C10.1658 10.0658 10.0093 10 9.84606 10C9.68285 10 9.52633 10.0658 9.41093 10.1831C9.29553 10.3003 9.23069 10.4592 9.23069 10.625V13.125C9.23069 13.2908 9.16586 13.4497 9.05046 13.5669C8.93505 13.6842 8.77853 13.75 8.61533 13.75L1.8463 13.75C1.6831 13.75 1.52658 13.6842 1.41117 13.5669C1.29577 13.4497 1.23094 13.2908 1.23094 13.125L1.23094 1.875C1.23094 1.70924 1.29577 1.55027 1.41117 1.43306C1.52658 1.31585 1.6831 1.25 1.8463 1.25L8.61533 1.25C8.77853 1.25 8.93505 1.31585 9.05046 1.43306C9.16586 1.55027 9.23069 1.70924 9.23069 1.875V4.375C9.23069 4.54076 9.29553 4.69973 9.41093 4.81694C9.52633 4.93415 9.68285 5 9.84606 5C10.0093 5 10.1658 4.93415 10.2812 4.81694C10.3966 4.69973 10.4614 4.54076 10.4614 4.375V1.875C10.4614 1.37772 10.2669 0.900805 9.92072 0.549174C9.57451 0.197544 9.10494 0 8.61533 0L1.8463 0C1.35669 0 0.887123 0.197544 0.540913 0.549174C0.194702 0.900805 0.000203133 1.37772 0.000203133 1.875L0.000203133 13.125Z"
      fill={color}
    />
  </svg>
);

ExitIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

ExitIcon.defaultProps = {
  className: ''
};

export default ExitIcon;
