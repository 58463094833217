import * as React from 'react';

function IconCourseArrowLeft(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.84107 4.22633C10.0983 3.94849 10.0983 3.51945 9.84107 3.24161V3.24161C9.55424 2.93184 9.06439 2.93184 8.77756 3.24162L4.93027 7.39674C4.61473 7.73752 4.61473 8.26379 4.93028 8.60457L8.77763 12.7597C9.06446 13.0695 9.55431 13.0695 9.84113 12.7597V12.7597C10.0984 12.4819 10.0984 12.0528 9.84113 11.775L6.3464 8.00065L9.84107 4.22633Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconCourseArrowLeft);
