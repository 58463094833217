import React from 'react';
import PropTypes from 'prop-types';

const CheckCircle = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0C2.23312 0 0 2.24401 0 5C0 7.75599 2.24401 10 5 10C7.76688 10 10 7.75599 10 5C10 2.23312 7.75599 0 5 0ZM7.44009 4.22658L4.84749 6.81917C4.70588 6.96078 4.5207 7.03704 4.32462 7.03704C4.12854 7.03704 3.94336 6.96078 3.80174 6.81917L2.53813 5.56645C2.39651 5.43573 2.32026 5.25055 2.32026 5.04357C2.32026 4.84749 2.39651 4.66231 2.53813 4.5207C2.67974 4.37908 2.86492 4.30283 3.061 4.30283C3.25708 4.30283 3.44227 4.37908 3.58388 4.5207L4.32462 5.26144L6.40523 3.18083C6.54684 3.03922 6.73203 2.96296 6.9281 2.96296C7.12418 2.96296 7.30937 3.03922 7.45098 3.18083C7.59259 3.31155 7.66885 3.49673 7.66885 3.7037C7.65795 3.91068 7.5817 4.09586 7.44009 4.22658Z"
      fill="#22BB3E"
    />
  </svg>
);

CheckCircle.propTypes = {
  className: PropTypes.string
};

CheckCircle.defaultProps = {
  className: ''
};

export default CheckCircle;
