import React from 'react';
import PropTypes from 'prop-types';

const Book = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.604 3.264v13.471" stroke="#fff" strokeWidth="2" />
    <path
      clipRule="evenodd"
      d="M14.962 1.68c-2.377 0-4.358 1.03-4.358 2.377 0-1.347-1.981-2.377-4.359-2.377-2.377 0-4.358 1.03-4.358 2.377v13.472c0-1.347 1.98-2.378 4.358-2.378 2.378 0 4.359 1.03 4.359 2.378 0-1.347 1.98-2.378 4.358-2.378 2.378 0 4.359 1.03 4.359 2.378V4.057c0-1.347-1.982-2.377-4.359-2.377z"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

Book.propTypes = {
  className: PropTypes.string
};

Book.defaultProps = {
  className: ''
};

export default Book;
