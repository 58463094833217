import React from 'react';
import PropTypes from 'prop-types';

const ThinArrowDown = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 25 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23 65L12 78L2 65" stroke="black" strokeWidth="3" />
    <path d="M12 75L12 0" stroke="black" strokeWidth="4" />
  </svg>
);

ThinArrowDown.propTypes = {
  className: PropTypes.string
};

ThinArrowDown.defaultProps = {
  className: ''
};

export default ThinArrowDown;
