import React from 'react';
import PropTypes from 'prop-types';

const Video = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 29 23"
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth=".25"
      d="M18.19 10.981l-5.38-3.424a.615.615 0 0 0-.944.519v6.847a.615.615 0 0 0 .944.519l5.38-3.424a.616.616 0 0 0 0-1.037zm-1.476.519l-3.62 2.304V9.195l3.62 2.305z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth=".25"
      d="M28.129.125H.74A.614.614 0 0 0 .125.74v21.52c0 .34.276.614.615.614H28.129c.34 0 .615-.274.615-.614V.74a.614.614 0 0 0-.615-.615zM5.016 10.885H1.355v-4.15h3.661v4.15zm-3.661 1.23h3.661v4.15H1.355v-4.15zm4.89 4.765V1.355h16.379v20.29H6.245V16.88zm21.27-5.995h-3.662v-4.15h3.662v4.15zm-3.662 1.23h3.662v4.15h-3.662v-4.15zm3.662-10.76v4.15h-3.662v-4.15h3.662zm-26.16 0h3.661v4.15H1.355v-4.15zm0 20.29v-4.15h3.661v4.15H1.355zm26.16 0h-3.662v-4.15h3.662v4.15z"
    />
  </svg>
);

Video.propTypes = {
  className: PropTypes.string
};

Video.defaultProps = {
  className: ''
};

export default Video;
