import React from 'react';
import PropTypes from 'prop-types';

const FourSquares = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.54494 12.0787H1.7949C1.39469 12.0787 1.06992 12.4034 1.06992 12.8036V20.5538C1.06992 20.954 1.39469 21.2787 1.7949 21.2787H9.54494C9.94515 21.2787 10.2699 20.954 10.2699 20.5538V12.8036C10.2699 12.4034 9.94515 12.0787 9.54494 12.0787ZM8.81997 19.8289H2.51988V13.5285H8.81997V19.8289Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M10.2699 1.80367C10.2699 1.40346 9.94516 1.07871 9.54494 1.07871H1.7949C1.39469 1.07871 1.06992 1.40346 1.06992 1.80367V9.55375C1.06992 9.95396 1.39469 10.2787 1.7949 10.2787H9.54494C9.94516 10.2787 10.2699 9.95396 10.2699 9.55375V1.80367ZM8.81997 8.8288H2.51988V2.52862H8.81997V8.8288Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M21.2699 1.80367C21.2699 1.40346 20.9452 1.07871 20.5449 1.07871H12.7949C12.3947 1.07871 12.0699 1.40346 12.0699 1.80367V9.55375C12.0699 9.95396 12.3947 10.2787 12.7949 10.2787H20.5449C20.9452 10.2787 21.2699 9.95396 21.2699 9.55375V1.80367ZM19.82 8.8288H13.5199V2.52862H19.82V8.8288Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M20.5449 12.0787H12.7949C12.3947 12.0787 12.0699 12.4034 12.0699 12.8036V20.5538C12.0699 20.954 12.3947 21.2787 12.7949 21.2787H20.5449C20.9452 21.2787 21.2699 20.954 21.2699 20.5538V12.8036C21.2699 12.4035 20.9455 12.0787 20.5449 12.0787ZM19.82 19.8289H13.5199V13.5285H19.82V19.8289Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
);

FourSquares.propTypes = {
  className: PropTypes.string
};

FourSquares.defaultProps = {
  className: ''
};

export default FourSquares;
