import React from 'react';
import PropTypes from 'prop-types';

const Vocabulary = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 22"
  >
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="1.8"
      d="M.908.908h20.183v20.183H.908z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="1.8"
      d="M5.495 16.413l4.587-11.835h1.835l4.587 11.835M7.972 11.459h6.082M3.66 17.422h3.67M14.67 17.422h3.67"
    />
  </svg>
);

Vocabulary.propTypes = {
  className: PropTypes.string
};

Vocabulary.defaultProps = {
  className: ''
};

export default Vocabulary;
