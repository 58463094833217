import React from 'react';
import PropTypes from 'prop-types';

const ClockIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.3792C18.0751 23.3792 23 18.4544 23 12.3792C23 6.30411 18.0751 1.37924 12 1.37924C5.92487 1.37924 1 6.30411 1 12.3792C1 18.4544 5.92487 23.3792 12 23.3792Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M12 6.37924V12.3792H18"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

ClockIcon.propTypes = {
  className: PropTypes.string
};

ClockIcon.defaultProps = {
  className: ''
};

export default ClockIcon;
