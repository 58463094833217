import React from 'react';
import PropTypes from 'prop-types';

const ZoomIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.8159 37.8411C45.5944 33.2549 47.4631 27.3904 47.0345 21.4635C46.6058 15.5367 43.9128 10.0021 39.5136 6.00722C35.1144 2.01238 29.3466 -0.136284 23.406 0.00670498C17.4654 0.149694 11.8077 2.57337 7.6058 6.77523C3.40393 10.9771 0.98026 16.6348 0.837271 22.5755C0.694283 28.5161 2.84295 34.2839 6.83779 38.683C10.8326 43.0822 16.3672 45.7753 22.2941 46.2039C28.2209 46.6325 34.0854 44.7639 38.6717 40.9853L57.6864 60L60.8306 56.8558L41.8159 37.8411ZM23.9989 41.7339C20.3302 41.7191 16.7482 40.6177 13.7049 38.5687C10.6617 36.5197 8.29376 33.615 6.90005 30.2214C5.50634 26.8277 5.14935 23.0971 5.87414 19.5007C6.59892 15.9043 8.373 12.6032 10.9724 10.0143C13.5718 7.42533 16.88 5.66456 20.4793 4.95425C24.0786 4.24394 27.8076 4.61594 31.1957 6.0233C34.5837 7.43065 37.4789 9.81026 39.5156 12.8617C41.5523 15.9132 42.6393 19.4996 42.6393 23.1684C42.6195 28.0991 40.6469 32.8212 37.1533 36.3007C33.6597 39.7803 28.9297 41.7339 23.9989 41.7339Z"
      fill="currentColor"
    />
    <path
      d="M26.0947 13.8853H21.603V20.7725H14.7158V25.2641H21.603V32.2262H26.0947V25.2641H33.0568V20.7725H26.0947V13.8853Z"
      fill="currentColor"
    />
  </svg>
);

ZoomIcon.propTypes = {
  className: PropTypes.string
};

ZoomIcon.defaultProps = {
  className: ''
};

export default ZoomIcon;
