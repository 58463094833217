import React from 'react';
import PropTypes from 'prop-types';

const TagHour = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.76732 3.56599C8.05866 4.45466 6.88932 6.23799 6.88932 8.29732C6.88932 10.72 8.50532 12.762 10.7173 13.412L8.69399 15.4353C8.56399 15.5653 8.39332 15.6307 8.22266 15.6307C8.05199 15.6307 7.88132 15.5653 7.75132 15.4353L0.41799 8.10199C0.292656 7.97732 0.222656 7.80732 0.222656 7.63066V1.63066C0.222656 1.26266 0.521323 0.963989 0.889323 0.963989H6.88932C7.06599 0.963989 7.23599 1.03399 7.36066 1.15932L9.76732 3.56599ZM2.88932 4.96399C2.88932 5.70066 3.48599 6.29732 4.22266 6.29732C4.95932 6.29732 5.55599 5.70066 5.55599 4.96399C5.55599 4.22732 4.95932 3.63066 4.22266 3.63066C3.48599 3.63066 2.88932 4.22732 2.88932 4.96399Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22266 8.29732C8.22266 6.08798 10.0133 4.29732 12.2227 4.29732C14.432 4.29732 16.2227 6.08798 16.2227 8.29732C16.2227 10.5067 14.432 12.2973 12.2227 12.2973C10.0133 12.2973 8.22266 10.5067 8.22266 8.29732ZM11.556 8.96398H14.8893V7.63065H12.8893V5.63065H11.556V8.96398Z"
      fill="currentColor"
    />
  </svg>
);

TagHour.propTypes = {
  className: PropTypes.string
};

TagHour.defaultProps = {
  className: ''
};

export default TagHour;
