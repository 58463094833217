import React from 'react';
import PropTypes from 'prop-types';

const CourseIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M4 10h4v3H4zM10 10h4v3h-4zM4 15h4v3H4zM10 15h4v3h-4zM16 10h4v3h-4z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 3h5a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h5V1a1 1 0 0 1 2 0v2h8V1a1 1 0 1 1 2 0v2zM2 21h20V7H2v14z"
      clipRule="evenodd"
    />
  </svg>
);

CourseIcon.propTypes = {
  className: PropTypes.string
};

CourseIcon.defaultProps = {
  className: ''
};

export default CourseIcon;
