import React from 'react';
import PropTypes from 'prop-types';

const Syllabus = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 18"
  >
    <path
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="1.5"
      d="M3.851 6.932a3.081 3.081 0 1 0 0-6.162 3.081 3.081 0 0 0 0 6.162zM3.851 17.203a3.081 3.081 0 1 0 0-6.162 3.081 3.081 0 0 0 0 6.162zM15.149 6.932a3.081 3.081 0 1 0 0-6.162 3.081 3.081 0 0 0 0 6.162zM15.149 17.203a3.081 3.081 0 1 0 0-6.162 3.081 3.081 0 0 0 0 6.162z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M3.338 6.932v4.108M15.149 6.932v4.108M5.905 6.419l7.19 6.676"
    />
  </svg>
);

Syllabus.propTypes = {
  className: PropTypes.string
};

Syllabus.defaultProps = {
  className: ''
};

export default Syllabus;
